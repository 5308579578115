import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Download24 from "@ausbom/icon/lib/icons/system/Download24";
import Button from '@ausbom/button'

function mapStateToProps(state) {
  return {
    results: state.results,
    locations: state.lastSearch.locations,
    calculate: state.lastSearch.calculate,
    filename: `${state.lastSearch.calculate}_${state.lastSearch.startYear}-${state.lastSearch.startMonth}_${state.lastSearch.endYear}-${state.lastSearch.endMonth}.csv`
  };
}

function TabularData(props) {

  TabularData.propTypes = {
    results: PropTypes.object,
    locations: PropTypes.array,
    calculate: PropTypes.string,
    filename: PropTypes.string
  };

  // // Setup options for dropdown boxes
  // const locationOptions = props.locations.map(l => {
  //   return { value: l, display: l };
  // });
  // const yearOptions = [
  //   ...new Set(
  //     Object.keys(props.results)
  //       .sort()
  //       .map(period => period.slice(4, 8))
  //   )
  // ].map(year => {
  //   return { value: year, display: year };
  // });
  // var [monthOptions] = useState(
  //   Object.keys(props.results)
  //     .sort()
  //     .filter(period => period.indexOf(`${locationOptions[0].value}${yearOptions[0].value}`) !== -1)
  //     .map(period => {
  //       return { value: period.slice(-2), display: period.slice(-2) };
  //     })
  // );

  // if (props.calculate === "monthly" || props.calculate === "yearly") {
  //   monthOptions = { 0: { value: "01" } };
  // }
  // Currently selected table key (combinations of location, year, month dropdown values)
  // const [tableKey, setTableKey] = useState(`${locationOptions[0].value}${yearOptions[0].value}${monthOptions[0].value}`);

  const results = props.results;

  // const resultColumns = [
  //   {
  //     Header: "Period",
  //     Cell: () => `${tableKey}`,
  //     show: false
  //   },
  //   {
  //     Header: "Missed Events",
  //     accessor: "missed_events",
  //     Cell: ({ row }) => `${row.missed_events[0]} ${row.missed_events[1]}`
  //   },
  //   {
  //     Header: "FAR",
  //     accessor: "far",
  //     Cell: ({ row }) => `${row.far[0]} ${row.far[1]}`
  //   },
  //   {
  //     Header: "Operationally Correct",
  //     accessor: "operationally_correct",
  //     Cell: ({ row }) => `${row.operationally_correct[0]} ${row.operationally_correct[1]}`
  //   },
  //   {
  //     Header: "PoD",
  //     accessor: "pod",
  //     Cell: ({ row }) => `${row.pod[0]} ${row.pod[1]}`
  //   }
  // ];

  const resultColumnsMonth = [
    {
      Header: "Period",
      Cell: ({ row }) => `${Object.keys(props.results)[row._index]}`
    },
    {
      Header: "Operationally Correct (%)",
      accessor: "operationally_correct",
      Cell: ({ row }) => `${row.operationally_correct ? row.operationally_correct.toFixed(2) : ''}`
    },
    {
      Header: "Missed Events (%)",
      accessor: "missed_events",
      Cell: ({ row }) => `${row.missed_events ? row.missed_events.toFixed(2) : ''}`
    },
    {
      Header: "FAR (0-1)",
      accessor: "far",
      Cell: ({ row }) => `${row.far ? row.far.toFixed(2) : ''}`
    },
    {
      Header: "PoD (0-1)",
      accessor: "pod",
      Cell: ({ row }) => `${row.pod ? row.pod.toFixed(2) : ''}`
    },
    {
      Header: "False Alarm Rate (0-1)",
      accessor: "false_alarm_rate",
      Cell: ({ row }) => `${row.false_alarm_rate ? row.false_alarm_rate.toFixed(2) : ''}`
    },
    {
      Header: "Hours Observed Below Minima",
      accessor: "hours_observed_below_minima",
      style: { 'whiteSpace': 'unset' },
      Cell: ({ row }) => `${row.hours_observed_below_minima ? row.hours_observed_below_minima.toFixed(2) : ''}`
    }
  ];

  const exportData = () => {
    let exportString = "";

    // Get column transformations and ref key
    const columns = props.calculate === "monthly" || props.calculate === 'daily' ? resultColumnsMonth : resultColumnsMonth;
    const key = props.calculate === "monthly" || props.calculate === 'daily' ? null : null;
    const resultSet = key ? props.results[key] : props.results;
    console.log(props.results)
    console.log(columns)
    console.log(key)
    console.log(resultSet)
    // Load data
    let rowIndex = 0;
    const data = Object.keys(resultSet).map(result => {
      return { ...resultSet[result], _index: rowIndex++ };
    });

    // Print column headers to CSV format
    exportString += columns.map(column => column.Header).join(",") + "\r\n";

    // Print data to CSV format using the cell manipulations defined in columns
    data.forEach(row => (exportString += columns.map(column => (column.Cell ? column.Cell({ row }) : row[column.accessor])).join(",") + "\r\n"));

    // Sends download request with the csv data
    downloadCsv(exportString);
  };

  // const exportAllData = () => {
  //   let exportString = "";

  //   // Print column headers
  //   exportString += resultColumns.map(column => column.Header).join(",") + "\r\n";

  //   // Print data to CSV format using the cell manipulations defined in resultColumns
  //   let rowIndex = 0;
  //   Object.keys(props.results)
  //     .sort()
  //     .forEach(period => {
  //       Object.keys(props.results[period]).forEach(row => {
  //         let data = props.results[period][row];
  //         data._index = rowIndex++;
  //         exportString +=
  //           period +
  //           "," +
  //           resultColumns
  //             .filter(column => column.Header !== "Period")
  //             .map(column => (column.Cell ? column.Cell({ row: data }) : data[column.accessor]))
  //             .join(",") +
  //           "\r\n";
  //       });
  //     });

  //   // Sends download request with the csv data
  //   downloadCsv(exportString);
  // };

  const downloadCsv = async csvText => {
    const blob = new Blob([csvText], { type: "text/csv" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = props.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Updates the state selected table key when as dropdown item changes
  // const handleDropDownSelect = () => {
  //   const newMonthOptions = Object.keys(props.results)
  //     .sort()
  //     .filter(period => period.indexOf(`${locationSelectRef.current.value}${yearSelectRef.current.value}`) !== -1)
  //     .map(period => {
  //       return { value: period.slice(-2), display: period.slice(-2) };
  //     });
  //   setMonthOptions(newMonthOptions);

  //   // If the year has changed and the currently select month does note exist in the new month set, reset the month to the first in the new list
  //   if (yearSelectRef.current.value !== year && newMonthOptions.map(o => o.value).indexOf(monthSelectRef.current.value) === -1) setTableKey(`${locationSelectRef.current.value}${yearSelectRef.current.value}${newMonthOptions[0].value}`);
  //   else setTableKey(`${locationSelectRef.current.value}${yearSelectRef.current.value}${monthSelectRef.current.value}`);

  //   setYear(yearSelectRef.current.value);
  // };

  // const renderDailyTables = () => (
  //   <div className="container">
  //     <div className="row">
  //       <div className="col">
  //         <label className="dropDownLabel dropDownLabelTop">Location</label>
  //       </div>
  //       <div className="col">
  //         <label className="dropDownLabel dropDownLabelTop">Year</label>
  //       </div>
  //       <div className="col">
  //         <label className="dropDownLabel dropDownLabelTop">Month</label>
  //       </div>
  //     </div>
  //     <div className="row">
  //       <DropDown dropDownType="otherParams" selectGroupId="tableLocationSelect" optionItems={locationOptions} handleDropSelect={handleDropDownSelect} selectRef={locationSelectRef} />
  //       <DropDown dropDownType="otherParams" selectGroupId="tableYearSelect" optionItems={yearOptions} handleDropSelect={handleDropDownSelect} selectRef={yearSelectRef} />
  //       <DropDown dropDownType="otherParams" selectGroupId="tableMonthSelect" optionItems={monthOptions} handleDropSelect={handleDropDownSelect} selectRef={monthSelectRef} />
  //     </div>
  //     <Button data-testid='downloadCSV' id="downloadButton" style={{ float: "right" }} onClick={exportAllData} small variant="secondary">
  //       Export all <Download24 primaryColor="#2461E5" secondaryColor="#2461E5" />
  //     </Button>
  //     <Button data-testid='downloadData' className='primaryButton' id="downloadButton" style={{ float: "right" }} onClick={exportData} small variant="secondary">
  //       Download .png <Download24 primaryColor="#2461E5" secondaryColor="#2461E5" />
  //     </Button>

  //     <h1>{tableKey}</h1>
  //     <ReactTable showPagination={true} data={Object.values(results[tableKey])} columns={resultColumns} defaultPageSize={16} />
  //   </div>
  // );

  const renderMonthlyTables = () => (
    <>
    <Button data-testid='monthlyDownloadData' id="downloadButton" style={{ float: "right" }} onClick={exportData} small variant="secondary">
        Export <Download24 primaryColor="#2461E5" secondaryColor="#2461E5" />
      </Button>
      <h4>Averages</h4>
      <ReactTable showPagination={true} data={Object.values(results)} columns={resultColumnsMonth} defaultPageSize={15} />
    </>
  );

  return props.calculate === "daily" ? renderMonthlyTables() : renderMonthlyTables();
}

const TabularDataComponent = connect(mapStateToProps)(TabularData);
export default TabularDataComponent;
