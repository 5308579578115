export const ADD_LOCATIONS = "ADD_LOCATIONS";
export const ADD_TIME_PERIOD = "ADD_TIME_PERIOD";
export const ADD_TITLE = "ADD_TITLE";
export const ADD_SEARCHES = "ADD_SEARCHES";
export const ADD_ALL_SEARCHES = "ADD_ALL_SEARCHES";
export const ADD_GROUPS = "ADD_GROUPS";
export const ADD_ALL_GROUPS = "ADD_ALL_GROUPS";
export const ADD_COMPARISON = "ADD_COMPARISON";
export const ADD_E_CONDITION = "ADD_E_CONDITION";
export const ADD_HOUR_RANGE = "ADD_HOUR_RANGE";
export const ADD_LOCATIONS_VALID = "ADD_LOCATIONS_VALID";
export const ADD_RESULTS = "ADD_RESULTS";
export const ANNOUNCE_502_ERROR = "ANNOUNCE_502_ERROR";
export const ADD_ERRORS = 'ADD_ERRORS';
export const ADD_START_MONTH = "ADD_START_MONTH";
export const ADD_START_YEAR = "ADD_START_YEAR";
export const ADD_END_MONTH = "ADD_END_MONTH";
export const ADD_END_YEAR = "ADD_END_YEAR";
export const ADD_PERIOD_OPTION = "ADD_PERIOD_OPTION";
export const ADD_START_DAY = "ADD_START_DAY";
export const ADD_END_DAY = "ADD_END_DAY";
export const ADD_CALCULATE = "ADD_CALCULATE";
export const ADD_TOGGLE = "ADD_TOGGLE";
export const ADD_OUTPUT = "ADD_OUTPUT";
export const ADD_GROUP_ID = "ADD_GROUP_ID";
export const ADD_SEARCH_LOADING = "ADD_SEARCH_LOADING";
export const ADD_LAST_SEARCH = "ADD_LAST_SEARCH";
export const ADD_SHOW_SUCCESS = "ADD_SHOW_SUCCESS";
export const ADD_SUCCESS_MSG = "ADD_SUCCESS_MSG";
export const ADD_EMAILS = "ADD_EMAILS";
export const ADD_EDIT_SEARCH = "ADD_EDIT_SEARCH";
export const ADD_EDIT_POPUP = "ADD_EDIT_POPUP";