/*
This component handles the user login function and will also do password reset.
*/
import { FormGroup, FormControl } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom"
import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import Popup from '../components/Popup/Popup';
import Button from "@ausbom/button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            username: "",
            password: "",
            authenticationCode:"",
            userHasAuthenticated: '',
            hideAuthPopup: true,
            hideLoginPopup: true,
            alertMessage: "Field can't be blank.",
            showDisclaimer: false,
            showConfirmationForm: false,
        };

        Login.propTypes = {
            userHasAuthenticated: PropTypes.func,
        }
    }

    /**
     * @desc Used for validation of username and password to check if they're empty.
     */
    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    /**
     * @desc Used for validation of authenticationCode to check if it empty.
     */
    validateAuthCode() {
        return this.state.authenticationCode.length > 0;
    }

    /**
     * @desc Handle updating the state when user types in the form.
     * @param {event} event used to get the id and value of the entered param.
     */
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    /**
     * @desc Uses the entered username and password to attempt a sign in.
     * If successful the MFA box will appear. 
     * If not an alert will be displayed with an error from the server.
     * @param {event} event
     */
    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });
        try {
            const user = await Auth.signIn(this.state.username, this.state.password);
            this.setState({ user, showConfirmationForm: true })
            this.setState({ hideLoginPopup: true })
        } catch (e) {
            this.setState({ alertMessage: e.message })
            this.setState({ hideLoginPopup: false })
            this.setState({ isLoading: false });
        }
    }

    /**
     * @desc Handles confirmation of the MFA code.
     * If successful, Auths current session changes the userHasAuthenticated prop.
     * If wrong code, an alert will be displayed.
     * @param {event} event 
     */
    confirmSignIn = (event) => {
        event.preventDefault();
        this.setState({ showDisclaimer: true })
    }

    openDisclaimer = () => {
        this.setState({ showDisclaimer: true })
    }

    closeDisclaimer = () => {
        this.setState({ showDisclaimer: false })
    }

    acceptDisclaimer = async (event) => {
        event.preventDefault();
        const { user, authenticationCode } = this.state
        try {
            await Auth.confirmSignIn(user, authenticationCode)
            this.props.userHasAuthenticated(true);
            this.setState({ hideAuthPopup: true })

        } catch (err) {
            this.setState({ alertMessage: err.message })
            this.setState({ hideAuthPopup: false, showDisclaimer: false })
        }
    }

    render() {
        return (
            <div className='container'>
                <div className="login-box">
                    <div className='container'>
                        <h4>Log in to TAF Verification</h4>
                        <form onSubmit={this.handleSubmit}>
                            <FormGroup controlId="username" bssize="large">
                                <label>Username</label>
                                <FormControl
                                    data-testid='username'
                                    autoFocus
                                    type="username"
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    disabled={this.state.showConfirmationForm}
                                />
                            </FormGroup>
                            <FormGroup controlId="password" bssize="large">
                                <label>Password</label>
                                <FormControl
                                    data-testid='password'
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    disabled={this.state.showConfirmationForm}
                                />
                            </FormGroup>
                            {this.state.hideLoginPopup === false &&
                                <Popup
                                    divClassName='errorDialog'
                                    textClassName='errorText'
                                    alertText={this.state.alertMessage} />
                            }
                            <Button data-testid='loginButton' id="downloadButton" disabled={!this.validateForm() || this.state.showConfirmationForm} small >
                                Login
                            </Button>
                            {/* <LoaderButton
                                className="primaryButton"
                                bssize="large"
                                disabled={!this.validateForm()}
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="Login"
                                loadingText="Logging in…"
                            /> */}
                            <div style={{ marginTop: 6, float: 'right' }}>
                                <Link to="/reset">
                                    Forgot your password?
                                </Link>
                            </div>
                        </form>
                        {/* <Button onClick={this.openDisclaimer} small>Test Disclaimer </Button> */}
                        {
                            this.state.showConfirmationForm && (
                                <form onSubmit={this.confirmSignIn}>
                                    <Fragment>
                                        <FormGroup controlId="authenticationCode" bssize="large">
                                            <label>Auth Code</label>
                                            <FormControl
                                            data-testid='code'
                                                autoComplete="off"
                                                value={this.state.authenticationCode}
                                                onChange={this.handleChange}
                                                type="text"
                                                autoFocus
                                            />
                                        </FormGroup>
                                        {this.state.hideAuthPopup === false &&
                                            <Popup
                                                divClassName='errorDialog'
                                                textClassName='errorText'
                                                alertText={this.state.alertMessage} />
                                        }
                                        <Button data-testid='confirm' id="downloadButton" onClick={this.confirmSignIn} disabled={!this.validateAuthCode()} small >
                                            Confirm code
                                        </Button>
                                        {/* <LoaderButton
                                        onClick={this.confirmSignIn}
                                        className='primaryButton'
                                        bssize="large"
                                        text="Confirm Code"
                                    /> */}
                                    </Fragment>
                                </form>
                            )
                        }

                    </div>
                </div>
                <Modal size="lg" centered show={this.state.showDisclaimer} onHide={() => this.closeDisclaimer()}>
          <Modal.Header closeButton>
            <Modal.Title>Terms of Use</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
              The information in the Aviation Verification System (AVS) is owned by the Bureau of Meteorology. This information is provided to authorised users of the AVS for the sole purpose of demonstrating the Bureau's verification of Bureau aerodrome forecasts against Bureau observed data.  You can share the information internally for this purpose only. The information must not be shared externally, copied, modified or used in any other way without the express, prior permission of the Bureau. The <a href="http://www.bom.gov.au/other/disclaimer.shtml?ref=ftr" target="_blank">Bureau's Disclaimer - Bureau of Meteorology (bom.gov.au)</a> applies to information on the AVS.
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.acceptDisclaimer} small>
              Accept 
            </Button>
          </Modal.Footer>
        </Modal>
            </div>
        );
    }
}
