import React from 'react';
import { Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from "./components/auth/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/auth/UnauthenticatedRoute";
import Login from './components/login/login-component'
import ResetPassword from './components/reset-password/reset-password-component'
import Search from './components/search/main-container/main-container'
import UserProfile from './components/user-profile/user-profile-component'
import Notfound from './components/auth/notfound'

export default ({ childProps }) =>
  <Switch>
    <AuthenticatedRoute path="/" exact component={Search} props={childProps} />
    <AuthenticatedRoute path="/user-profile" exact component={UserProfile} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/reset" exact component={ResetPassword} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={Notfound} />
  </Switch>;
