import React from "react";
import Modal from "react-bootstrap/Modal";
import ReactTable from "react-table";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { addLocation, addGroups, addLocationsValid, addErrors } from "../../../../../actions/index";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import { FaTrashAlt } from "react-icons/fa";
import { API } from "aws-amplify";
import validateInput from "../../../../validate-text/validateText";
import { Tab as NotTab, TabList, TabPanel } from "@ausbom/tabs";
import Button from "@ausbom/button";
import { createBrowserHistory } from "history";

// Handle props used for this component.
function mapDispatchToProps(dispatch) {
  return {
    addLocation: locations => dispatch(addLocation(locations)),
    addGroups: groups => dispatch(addGroups(groups)),
    addErrors: errors => dispatch(addErrors(errors)),
    addLocationsValid: locationsValid => dispatch(addLocationsValid(locationsValid))
  };
}

const mapStateToProps = state => {
  return {
    groups: state.groups,
    allGroups: state.allGroups,
    errors: state.errors,
    lastSearch: state.lastSearch
  };
};

class SavedLocation extends React.Component {
  _isMounted = false;

  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      selectedRow: "",
      isLoading: false,
      hideSuccess: true,
      groups: {},
      locationInputValue: "",
      locationsValid: true,
      task: "",
      locations: "",
      selectedTab: "today"
    };

    SavedLocation.propTypes = {
      setKey: PropTypes.func,
      groups: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      allGroups: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      addGroups: PropTypes.func,
      groupId: PropTypes.string,
      locationsValid: PropTypes.bool,
      addLocationsValid: PropTypes.func,
      addLocation: PropTypes.func,
      lastSearch: PropTypes.object,
      errors: PropTypes.array,
      addErrors: PropTypes.func

    };

    this.render = this.render.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.deleteGroupPopup = this.deleteGroupPopup.bind(this);
  }

  /**
  * @desc Check if user is authed, if not send to login page.
  * If authed then retrieve the users saved location groups and user groups saved location groups.
  */
  async componentDidMount() {
    this._isMounted = true;
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (e) {
      if (e !== 'No current user') {
        console.log(e)
        const customHistory = createBrowserHistory();
        customHistory.replace('/#/login')
        customHistory.go()
      }
    }
    const header = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };
    if (this._isMounted) {
      let data = await API.get("groups", `/groups`, header);
      this.props.addGroups({ groups: data.user });
    }
  }

  /**
   * @desc If fill is selected, update the location input to reflect the selected row and update the props.
   * @param {*} option if the button was clicked under groups or allGroups.
   * @param {*} row What row number was the fill form button clicked on.
   */
  async fillForm(option, row) {
    const data = this.props[option][row._index];
    let locationsArray = [];
    locationsArray.push(data.locations);
    this.props.addLocation({ locations: locationsArray });
    this.props.addLocationsValid({ locationsValid: true });
  }

  /**
   * @desc Show delete group popup and assign the task and row to the selection.
   * @param {*} task If the selection has been to delete or edit the group.
   * @param {*} row What row has been selected.
   */
  async deleteGroupPopup(task, row) {
    this.handleModel("show", true);
    await this.setState({ selectedRow: row._index });
    await this.setState({ task: task });
    await this.setState({ locationInputValue: this.props.groups[this.state.selectedRow].locations });
  }

  // Delete the selected saved search.
  // Get the current selected row id to send with the API request to the lambda.
  // Delete the selected row from the front end table.
  /**
   * @desc Check if user is authed, if not, send to login page, otherwise get jwtToken.
   * Get all ids needed to make request.
   * Make API call and edit or delete the selected location group then update the table to show the change.
   */
  deleteGroup = async () => {
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (e) {
      if (e !== 'No current user') {
        const customHistory = createBrowserHistory();
        customHistory.replace('/#/login')
        customHistory.go()
      }
    }
    this.setState({ isLoading: true });
    let specialId = this.props.groups[this.state.selectedRow].id;
    let group = this.props.groups[this.state.selectedRow].group;
    let locations;
    let otherId;
    let groups = this.props.groups;
    if (this.state.task === "delete") {
      locations = this.props.groups[this.state.selectedRow].locations;
      otherId = this.state.selectedRow;
      delete groups[otherId];
    } else {
      locations = this.state.locations;
    }
    try {
      const header = {
        headers: { Authorization: `Bearer ${jwtToken}` }
      };
      await API.put("groups", `/groups/${specialId}/${this.state.task}/${locations}/${group}`, header);
    } catch (error) {
      console.log(error);
    }
    this.handleModel("show", false);
    this.props.addGroups({ groups: groups });
    this.setState({ isLoading: false });
    this.componentDidMount();
  };

  /**
   * @desc Accepts model name and true or false for closing it or opening it.
   * @param {string} variable To determine what modal to show.
   * @param {boolean} bool true or false.
   */
  handleModel(variable, bool) {
    this.setState({ showTitleAlert: true });
    this.setState({ [variable]: bool });
  }

  /**
   * @desc Handles verifying the location ids typed into the text box.
   * Validates the input through the validateInput function.
   * @param {event} event used to get the value of the text input box.
   * @result Display error if validateInput returns an error, otherwise add the locations to the locations prop.
   */
  handleLocationChange = event => {
    const value = event.target.value;
    this.setState({ locationInputValue: value });
    let validInput = validateInput.validateInput(value);
    this.setState({ locationsValid: validInput.valid });
    if (validInput.valid) this.setState({ locations: validInput.body });
  };

  render() {
    const data = this.props.groups;
    const columns = [
      {
        Header: "Title",
        accessor: "title",
        width: 150,
        style: { width: "20%" }
      },
      {
        Header: "Locations",
        accessor: "locations",
        style: { whiteSpace: "normal" }, // allow for words wrap inside only this cell
        Cell: ({ row }) => `${row.locations.join(", ")}.`
      },
      {
        Header: "Group",
        accessor: "group",
        width: 150,
        style: { width: "20%" }
      },
      {
        Header: "Actions",
        width: 150,
        Cell: ({ row }) => (
          <>
            <Button className="tableButton"
              data-testid="editButton"
              id={row._index}
              onClick={() => {
                this.deleteGroupPopup("edit", row);
              }}
              small
            >
              Edit
            </Button>
            <br />
            <Button className="tableButton"
              data-testid="deleteButton"
              id={row._index}
              onClick={() => {
                this.deleteGroupPopup("delete", row);
              }}
              small
            >
              <FaTrashAlt />
            </Button>
          </>
        )
      }
    ];
    const allData = this.props.allGroups;
    const columns2 = [
      {
        Header: "Title",
        accessor: "title",
        width: 150,
        style: { width: "20%" }
      },
      {
        Header: "Locations",
        accessor: "locations",
        style: { whiteSpace: "normal" }, // allow for words wrap inside only this cell
        Cell: ({ row }) => `${row.locations.join(", ")}.`
      },
      {
        Header: 'Group',
        accessor: 'group',
        width: 150
      },
      {
        Header: "Actions",
        width: 150,
        Cell: ({ row }) => (
          <>
            <Button className="tableButton"
              data-testid="fillButton"
              id={row._index}
              onClick={() => {
                this.fillForm("allGroups", row);
                this.props.setKey("currentSearch");
              }}
              small
            >
              Fill Form
            </Button>
          </>
        )
      }
    ];

    return (
      <div>
        <TabList>
          <NotTab data-testid='yourLocations' className="tabTest" label="Your locations" active={this.state.selectedTab === "today"} tabId="today-2" onClick={() => this.setState({ selectedTab: "today" })} />
          <NotTab data-testid='sharedLocations' label="Shared locations" active={this.state.selectedTab === "7day"} tabId="7-day-2" onClick={() => this.setState({ selectedTab: "7day" })} />
        </TabList>
        <TabPanel active={this.state.selectedTab === "today"} tabId="today-2">
          <ReactTable data={data} columns={columns} defaultPageSize={10} />
        </TabPanel>
        <TabPanel active={this.state.selectedTab === "7day"} tabId="7-day-2">
          <ReactTable data={allData} columns={columns2} defaultPageSize={10} />{" "}
        </TabPanel>

        {this.state.task === "delete" && (
          <Modal data-testid="deleteModal" size="sm" show={this.state.show} onHide={() => this.handleModel("show", false)}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Group </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="locationsPara">Are you sure you want to delete this group?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button data-testid="secondButton" onClick={this.deleteGroup} small>
                Delete group
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.task === "edit" && (
          <Modal data-testid="editModal" size="lg" show={this.state.show} onHide={() => this.handleModel("show", false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Group </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input data-testid="locations-input" id="location-text" name="locations" className={(!this.state.locationsValid && Object.keys(this.props.lastSearch).length !== 0) || (!this.state.locationsValid && this.state.locationInputValue.length !== 0) ? 'formInputError inputWidthWide' : "formInput inputWidthWide"} type="text" placeholder="Enter locations" autoComplete="off" onChange={this.handleLocationChange} value={this.state.locationInputValue} />
            </Modal.Body>
            <Modal.Footer>
              <Button data-testid="saveEditButton" disabled={!this.state.locationsValid} onClick={this.deleteGroup} small>
                Edit group
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}
const SavedLocations = connect(mapStateToProps, mapDispatchToProps)(SavedLocation);

export default SavedLocations;
