/*
*/
import React from "react";
import { connect } from "react-redux";
import DropDown from "../../../../../components/DropDown/DropDown";
import { addComparison, addECondition, addHourRange } from "../../../../../../actions/index";
import PropTypes from "prop-types";
// import Tooltip, { TooltipButton } from "@ausbom/tooltip";

function mapDispatchToProps(dispatch) {
  return {
    addComparison: comparison => dispatch(addComparison(comparison)),
    addECondition: eCondition => dispatch(addECondition(eCondition)),
    addHourRange: hourRange => dispatch(addHourRange(hourRange)),
  };
}

const mapStateToProps = state => {
  return {
    comparison: state.currentSearch.comparison,
    eCondition: state.currentSearch.eCondition,
    hourRange: state.currentSearch.hourRange
  };
};

class OtherParams extends React.Component {
  constructor() {
    super();
    this.state = {
      // Remove comparions for now as we don't have any Persistence data
      // comparisonOptions: { 0: { value: "VERIFICATION", display: "Verification", disabled: false }, 1: { value: "PERSISTENCE", display: "Persistence", disabled: false } },
      eConditionOptions: { 0: { value: "visibility", display: "Visibility", disabled: false }, 1: { value: "cloud", display: "Cloud", disabled: false }, 2: { value: "combined", display: "Combined", disabled: false }, 3: { value: "weather", display: "Thunderstorms", disabled: false }, 4: { value: "fog", display: "Fog", disabled: false } },
      hourRangeOptions: { 0: { value: "full_validity", display: "Full validity" , disabled: false}, 1: { value: "validity_1-6", display: "Validity 1-6 hrs", disabled: false }, 2: { value: "validity_7-12", display: "Validity 7-12 hrs", disabled: false }, 3: { value: "validity_13-18", display: "Validity 13-18 hrs", disabled: false }, 4: { value: "validity_19-24", display: "Validity 19-24 hrs", disabled: false }, 5: { value: "validity_25-30", display: "Validity 25-30 hrs", disabled: false } }
    };

    OtherParams.propTypes = {
      comparison: PropTypes.string,
      eCondition: PropTypes.string,
      hourRange: PropTypes.string,
      addComparison: PropTypes.func,
      addECondition: PropTypes.func,
      addHourRange: PropTypes.func,
    };
  }

  /**
   * @desc Checks what option dropdown was called and then sets that prop to the value selected.
   * @param {event} event determines what dropdown was used and the value selected.
   */
  handleDropdownSelect = event => {
    const { id, value } = event.target;
    switch (id) {
      case "comparison":
        this.props.addComparison({ comparison: "VERIFICATION" });
        break;
      case "eCondition":
        this.props.addECondition({ eCondition: value });
        break;
      case "hourRange":
        this.props.addHourRange({ hourRange: value });
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div className="searchBoxes search-components searchBoxBottom">
        <div>
          <h4 className="titleText">Other Parameters</h4>
          <>
            <div className="row">
              <div className="col">
                <label className="dropDownLabel dropDownLabelTop">Environmental condition</label>
              </div>
            </div>
            <div className="row">
              {/* <DropDown dropDownType="otherParams" selectGroupId="comparison" selectRef="compInput" optionItems={this.state.comparisonOptions} handleDropSelect={this.handleDropdownSelect} /> */}
              <DropDown dropDownType="otherParams" selectGroupId="eCondition" selectRef="eConditionInput" optionItems={this.state.eConditionOptions} handleDropSelect={this.handleDropdownSelect} />
            </div>
            <div className="row">
              <div className="col">
                <label className="dropDownLabel dropDownLabelTop">Hour range</label>
              </div>
            </div>
            <div className="row">
              <DropDown dropDownType="otherParams" selectGroupId="hourRange" selectRef="hourRange" optionItems={this.state.hourRangeOptions} handleDropSelect={this.handleDropdownSelect} />
            </div>
          </>
        </div>
      </div>
    );
  }
}
const OtherParamsExport = connect(mapStateToProps, mapDispatchToProps)(OtherParams);

export default OtherParamsExport;
