/*
*/
import Modal from "react-bootstrap/Modal";
import React from "react";
import { connect } from "react-redux";
import ResultTabs from "../result-tab-component/result-tabs-component";
import PropTypes from "prop-types";
import { IoMdInformationCircleOutline } from "react-icons/io";
import ErrorBoundary from "../../components/ErrorBoundry";

const mapStateToProps = state => {
  return {
    results: state.results,
    outputs: state.lastSearch.outputs
  };
};

class SearchResults extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hideSuccess: true
    };
    this.myRef = React.createRef();
    SearchResults.propTypes = {
      results: PropTypes.object
    };
    this.infoPopup = this.infoPopup.bind(this);
  }

  /**
   * @desc Detects of results prop has changed and calls scrollToMyRef function to scroll the user down.
   * @param {*} prevProps holds the previous state of props before change.
   */
  async componentDidUpdate(prevProps) {
    if (this.props.results !== prevProps.results) {
      this.scrollToMyRef();
    }
  }

  /**
   * @desc Scrolls the user down to show the results section.
   */
  scrollToMyRef = () => window.scrollTo(-10, this.myRef.current.offsetTop);

   /**
   * @desc Accepts model name and true or false for closing it or opening it.
   * @param {string} variable To determine what modal to show.
   * @param {boolean} bool true or false.
   */
  handleModel(variable, bool) {
    this.setState({ showTitleAlert: true });
    this.setState({ [variable]: bool });
  }

  /**
  * @desc builds the popup alert to display for success or errors.
  * @param {string} variable the state variable to assign true or false.
  * @param {boolean} bool is assigned to the variable.
  * @param {string} textVariable The type of alert to be displayed.
  * @param {string} textMessage The text to be displayed in the alert.
  */
  handlePopupAlerts(variable, bool, textVariable, textMessage) {
    this.setState({ [textVariable]: textMessage });
    this.setState({ [variable]: bool });
  }

  /**
   * @desc Displays the info popup on click.
   */
  async infoPopup() {
    this.handleModel("show", true);
  }

  render() {
    return (
      Object.keys(this.props.results).length > 0 && (
        <div ref={this.myRef} className="container main-container search">
          {" "}
          <Modal size="lg" show={this.state.show} onHide={() => this.handleModel("show", false)}>
            <Modal.Header closeButton>
              <Modal.Title>Definitions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <table className="table table-hover" id="data-requirements">
              <thead className="thead-light">
                <tr>
                  <th className="tableHead">Term</th>
                  <th className="tableHead">Abbreviation</th>
                  <th className="tableHead">Definition</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Missed Events</td>
                  <td></td>
                  <td>This metric is an expression of how many weather events (TS, FG, and VIS) that resulted in below minima conditions, were not forecast. </td>
                </tr>
                <tr>
                  <td>False Alarm Ratio</td>
                  <td>FAR</td>
                  <td>The False Alarm Ratio metric measures what fraction of forecast did not occur.</td>
                </tr>
                <tr>
                  <td>Operationally Correct</td>
                  <td>Op</td>
                  <td>This metric is an expression of accuracy of the forecast, it is the overall fraction of the forecasts that were correct.</td>
                </tr>
                <tr>
                  <td>Probability Of Detection</td>
                  <td>PoD</td>
                  <td>Probability Of Detection metric is defined as what frequency of events were correctly forecast for a given phenomenon.</td>
                </tr>
                <tr>
                  <td>Total Forecast hours</td>
                  <td></td>
                  <td>These are the total hours forecast for respective phenomenon for the selected time period.
</td>
                </tr>
                <tr>
                  <td>False Alarm Rate</td>
                  <td>FA</td>
                  <td>An indication of the rate of forecasts for below alternate minima conditions actually observed to be above alternate minima.</td>
                </tr>
                <tr>
                  <td>Hours Observed Below Minima</td>
                  <td>HOBM</td>
                  <td>This is the total number of hours conditions were observed below minima for the defined time period.</td>
                </tr>
              </tbody>
            </table>
            </Modal.Body>
          </Modal>
          <div>
            <h3 style={{ display: "contents" }}>Search results</h3>{" "}
            <span onClick={this.infoPopup} style={{ float: "right", fontSize: "26px", cursor: "pointer" }} className="infoButton">
              <IoMdInformationCircleOutline />
            </span>
          </div>
          <ErrorBoundary>
            <ResultTabs />
          </ErrorBoundary>
        </div>
      )
    );
  }
}
const SearchResultsExport = connect(mapStateToProps)(SearchResults);

export default SearchResultsExport;

