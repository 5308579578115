/*
*/
import React from 'react';
import { connect } from "react-redux";
import { addTimePeriod, addStartMonth, addStartYear, addEndMonth, addEndYear, addPeriodOption, addStartDay, addEndDay } from "../../../../../../actions/index";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import Popup from '../../../../../components/Popup/Popup';
import "react-datepicker/dist/react-datepicker.css";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tabs'
import DropDown from "../../../../../components/DropDown/DropDown";
import { addCalculate } from "../../../../../../actions/index";


// Handle props used for this component.
function mapDispatchToProps(dispatch) {
    return {
        addTimePeriod: timePeriod => dispatch(addTimePeriod(timePeriod)),
        addStartMonth: startMonth => dispatch(addStartMonth(startMonth)),
        addStartYear: startYear => dispatch(addStartYear(startYear)),
        addEndMonth: endMonth => dispatch(addEndMonth(endMonth)),
        addEndYear: endYear => dispatch(addEndYear(endYear)),
        addPeriodOption: periodOption => dispatch(addPeriodOption(periodOption)),
        addStartDay: startDay => dispatch(addStartDay(startDay)),
        addEndDay: endDay => dispatch(addEndDay(endDay)),
        addCalculate: calculate => dispatch(addCalculate(calculate))
    };
}

const mapStateToProps = state => {
    return {
        timePeriod: state.currentSearch.timePeriod,
        startMonth: state.currentSearch.startMonth,
        startYear: state.currentSearch.startYear,
        endMonth: state.currentSearch.endMonth,
        endYear: state.currentSearch.endYear,
        periodOption: state.currentSearch.periodOption,
        startDay: state.currentSearch.startDay,
        endDay: state.currentSearch.endDay,
        toggle: state.toggle,
        calculate: state.currentSearch.calculate
    };
};

class Times extends React.Component {

    constructor() {
        super();
        this.state = {
            timePeriods: { "Current year": ["0"], "1 year": ["1"], "2 year": ["2"], "3 year": ["3"] },
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            defaultEndDate: new Date(),
            customEndDate: new Date(),
            defaultStartDate: new Date(),
            customStartDate: new Date(),
            hideDateError: true,
            open: true,
            tab: 'defaultPeriod',
            calculate: 'monthly',
            calculateOptions: { 0: { value: "monthly", display: "Monthly", disabled: false }, 1: { value: "daily", display: "Daily", disabled: false }, 2: { value: "yearly", display: "Yearly", disabled: false } },
        };

        Times.propTypes = {
            timePeriod: PropTypes.string,
            periodOption: PropTypes.string,
            startDay: PropTypes.number,
            endDay: PropTypes.number,
            startMonth: PropTypes.number,
            endMonth: PropTypes.number,
            startYear: PropTypes.number,
            endYear: PropTypes.number,
            toggle: PropTypes.bool,
            addTimePeriod: PropTypes.func,
            addStartMonth: PropTypes.func,
            addStartYear: PropTypes.func,
            addEndMonth: PropTypes.func,
            addEndYear: PropTypes.func,
            addPeriodOption: PropTypes.func,
            addStartDay: PropTypes.func,
            addEndDay: PropTypes.func,
            calculate: PropTypes.string,
            addCalculate: PropTypes.func
        }

        this.defaultTimeDropDown = React.createRef();
        this.handleDropSelect = this.handleDropSelect.bind(this);
        this.setKey = this.setKey.bind(this)
        this.addDays = this.addDays.bind(this)
        // this.handleChange = this.handleChange.bind(this)
    }

    /**
     * @desc Handles changing the props for time optiosn when tabs are changed.
     * @param {*} keys to identify selected tab.
     */
    async setKey(keys) {
        await this.setState({ tab: keys })
        if (this.state.tab === 'defaultPeriod') {
            let timePeriod = { "target": { "value": this.defaultTimeDropDown.current.value } }
            await this.handleDropSelect(timePeriod)
            await this.props.addPeriodOption({ periodOption: 'default' })
        } else if (this.state.tab === 'customPeriod') {
            await this.setStartDate(this.calculateEndDate())
            await this.setEndDate(this.calculateEndDate())
            await this.props.addPeriodOption({ periodOption: 'custom' })
        }
    }

    /**
     * @desc the length of a default period is always less then 12.
     * used to call functions for updating date information based on load or change from saved searches fill form.
     */
    componentDidMount() {
        if (this.props.timePeriod.length <= 12) {
            let event = { "target": { "value": this.state.timePeriods[this.props.timePeriod][0] } }
            this.handleDropSelect(event)
            this.setState({ tab: 'defaultPeriod' })
        } else {
            let dates = this.props.timePeriod.split(" ");
            let startDate = new Date(dates[0])
            let endDate = new Date(dates[2])
            this.setStartDate(startDate)
            this.setEndDate(endDate)
            this.setState({ tab: 'customPeriod' })
        }
    }

    /**
     * @desc Update date information if change in time period props is detected, or tab changed.
     * @param {*} prevProps used to compare against current props to detect change.
     */
    componentDidUpdate(prevProps) {
        if (this.props.timePeriod !== prevProps.timePeriod || this.props.toggle !== prevProps.toggle) {
            if (this.props.timePeriod.length <= 12) {
                this.defaultTimeDropDown.current.value = this.state.timePeriods[this.props.timePeriod]
                let event = { "target": { "value": this.state.timePeriods[this.props.timePeriod][0] } }
                this.handleDropSelect(event)
                this.setState({ tab: 'defaultPeriod' })
            } else {
                let dates = this.props.timePeriod.split(" ");
                let startDate = new Date(dates[0])
                let endDate = new Date(dates[2])
                this.setStartDate(startDate)
                this.setEndDate(endDate)
                this.setState({ tab: 'customPeriod' })
            }
        }
    }

    /**
     * @desc handles updating all date information based on selection of a default date period.
     * @param {event} event used to determine what the user selected to create date range.
     */
    async handleDropSelect(event) {
        const current = event.target.value
        const currentDate = new Date()
        this.props.addStartMonth({ startMonth: current === "0" ? 1 : currentDate.getMonth() + 1 })
        this.props.addStartYear({ startYear: currentDate.getFullYear() - current })
        this.props.addEndMonth({ endMonth: currentDate.getMonth() })
        this.props.addEndYear({ endYear: currentDate.getFullYear() })
        this.props.addTimePeriod({ timePeriod: current === "0" ? `Current year` : `${current} year` });
        this.setState({ defaultStartDate: new Date(`01/${this.props.startMonth}/${this.props.startYear}`) })
        this.setState({ defaultEndDate: new Date(`01/${this.props.endMonth}/${this.props.endYear}`) })
    }

    /**
     * @desc creates the start date and assigns the dates to the props.
     * @param {Date} date used to create all date information.
     */
    async setStartDate(date) {
        this.setState({ customStartDate: date })
        this.setState({ startDate: date })
        this.props.addStartDay({ startDay: date.getDate() })
        this.props.addStartMonth({ startMonth: date.getMonth() + 1 })
        this.props.addStartYear({ startYear: date.getFullYear() })
    }

    /**
     * @desc creates the end date and assigns the dates to the props.
     * @param {Date} date used to create all date information.
     */
    async setEndDate(date) {
        this.setState({ customEndDate: date })
        this.setState({ endDate: date })
        this.props.addEndDay({ endDay: date.getDate() })
        this.props.addEndMonth({ endMonth: date.getMonth() + 1 })
        this.props.addEndYear({ endYear: date.getFullYear() })
    }

    addDays(days) {
        console.log(this.state.startDate);
        if (this.state.startDate) {
            var today = new Date();
            const copy = new Date(Number(this.state.startDate))
            copy.setDate(this.state.startDate.getDate() + days)
            console.log(copy)
            if (copy >= today) {
                return today
            } else {
                return copy;
            }
        }
    }

    calculateEndDate() {
        let date = new Date()
        return new Date(date.setDate(date.getDate() - 2))
    }

    /**
    * @desc Checks what option dropdown was called and then sets that prop to the value selected.
    * @param {event} event determines what dropdown was used and the value selected.
    */
    handleCalculateSelect = event => {
        const { value } = event.target;
        this.props.addCalculate({ calculate: value });
    };

    render() {
        return (
            <div className="searchBoxes search-components" style={{ marginBottom:"15px" }}>
                <h4 className="titleText">Time period</h4>
                <>
                    <div>
                        <label className="dropDownLabel dropDownLabelTop">Calculate </label>
                    </div>
                    <div className="calculate-container">
                        <DropDown dropDownType="otherParams" selectGroupId="calculate" selectRef="calculate" optionItems={this.state.calculateOptions} handleDropSelect={this.handleCalculateSelect} />
                    </div>  
                    <Tabs defaultActiveKey="defaultPeriod" activeKey={this.state.tab} onSelect={this.setKey} style={{ borderBottom: 'none' }}>
                        <Tab className='tabHeader' eventKey="defaultPeriod" title="Default period" data-testid="defaultTab" style={{ border: "1px solid #dee2e6", paddingRight: 5, paddingLeft: 5 }}>
                            <div className="row">

                                <div className="col">
                                    <label className="dropDownLabel dateLabel">Default date</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <select id="timeselector" data-testid='timePeriod' ref={this.defaultTimeDropDown} className="formDropDown dropDownWidthWide" onChange={this.handleDropSelect}>
                                            {Object.keys(this.state.timePeriods).map((dg, i) => <option key={i} value={this.state.timePeriods[dg]}>{dg}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab className='tabHeader' eventKey="customPeriod" title="Custom period" data-testid="customTab" style={{ border: "1px solid #dee2e6", paddingRight: 5, paddingLeft: 5 }}>
                            <div className="row">
                                <div className="col">
                                    <label className="dropDownLabel dateLabel">Start date</label>
                                </div>
                                <div className="col">
                                    <label className="dropDownLabel dateLabel">End date</label>
                                </div>
                            </div>                                
                            { this.props.calculate === 'daily' &&
                            <div className="row">
                                <div className="col">
                                    <DatePicker
                                        data-testid='startDaye'
                                        className="periodDropDowns"
                                        selected={this.state.startDate}
                                        onChange={date => this.setStartDate(date)}
                                        dateFormat="dd MMMM yyyy"
                                        minDate={new Date("2017")}
                                        maxDate={this.state.endDate}
                                        showDisabledMonthNavigation
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selectsStart
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        shouldCloseOnSelect={false}
                                    />
                                </div>
                                <div className="col">
                                    <DatePicker
                                        data-testid='startDaye'
                                        className="periodDropDowns"
                                        selected={this.state.endDate}
                                        onChange={date => this.setEndDate(date)}
                                        dateFormat="dd MMMM yyyy"
                                        minDate={this.state.startDate}
                                        maxDate={this.calculateEndDate()}
                                        showDisabledMonthNavigation
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selectEnd
                                        startDate={this.state.startDate}
                                        shouldCloseOnSelect={false}
                                        endDate={this.state.endDate}
                                    />
                                
                                </div>
                            
                            </div>
                            }
                            { this.props.calculate !== 'daily' &&
                            <div className="row">
                                <div className="col">
                                    <DatePicker
                                        data-testid='startDaye'
                                        className="periodDropDowns"
                                        selected={this.state.startDate}
                                        onChange={date => this.setStartDate(date)}
                                        dateFormat="MMMM yyyy"
                                        minDate={new Date("2010")}
                                        maxDate={this.state.endDate}
                                        showDisabledMonthNavigation
                                        dropdownMode="select"
                                        selectsStart
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        shouldCloseOnSelect={false}
                                        showMonthYearPicker
                                    />
                                </div>
                                <div className="col">
                                    <DatePicker
                                        data-testid='startDaye'
                                        className="periodDropDowns"
                                        selected={this.state.endDate}
                                        onChange={date => this.setEndDate(date)}
                                        dateFormat="MMMM yyyy"
                                        minDate={this.state.startDate}
                                        maxDate={new Date()}
                                        showDisabledMonthNavigation
                                        dropdownMode="select"
                                        selectEnd
                                        showMonthYearPicker                                       
                                        startDate={this.state.startDate}
                                        shouldCloseOnSelect={false}
                                        endDate={this.state.endDate}
                                    />
                                
                                </div>
                            
                            </div>
                            }
                            
                        </Tab>
                    </Tabs>
                </>
                {this.state.hideDateError === false &&
                    <Popup
                        divClassName='errorDialog'
                        textClassName='errorText'
                        alertText="The start date can't be after the end date." />
                }
            </div>
        )
    }
}
const TimesExport = connect(mapStateToProps, mapDispatchToProps)(Times);

export default TimesExport
