

export default ({
    validateInput(stateLocation) {
        var orig = stateLocation
        var regEx = new RegExp(/^[A-Z, ]+$/);
        stateLocation = stateLocation.toUpperCase();
        var thing = new RegExp(/[A-Z]+/)
        var locationString = stateLocation.replace(/[, ]+/g, '')
        locationString = locationString.match(thing)

        // Check if locations box is empty or not
        if (stateLocation === '') {
            return {'body':orig, 'valid': false, 'hideAlert': false, 'alertMessage' : "Field can't be blank."}
        }
        // Check if locations entered contain characters aside from letters, commas and spaces
        if (regEx.test(stateLocation) === false) {
            return {'body':orig, 'valid': false, 'hideAlert': false, 'alertMessage' : "Only capital letters, commas and spaces allowed."}
        }
        let stringLength = (locationString[0].length % 4 === 0)
        // Check if each location is 4 characters in length or not
        if (stringLength === false) {
            return {'body':orig, 'valid': false, 'hideAlert': false, 'alertMessage' : "Each location must be 4 characters long."}
        }
        // Check for dupliate locations
        stateLocation = stateLocation.replace(/[, ]+/g, '')
        var regex = new RegExp('.{' + 4 + '}|.{1,' + Number(4 - 1) + '}', 'g');
        let test = stateLocation.match(regex)
        let findDuplicates = test => test.filter((item, index) => test.indexOf(item) !== index)
        if (findDuplicates(test).length !== 0) {
            return {'body':orig, 'valid': false, 'hideAlert': false, 'alertMessage' : "Duplicate locations have been entered."}
        }
        return {'body':(stateLocation.match(regex)), 'valid': true, 'hideAlert': true, 'alertMessage' : ""}
    }
})