import React from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import saveAs from "file-saver";
import Download24 from "@ausbom/icon/lib/icons/system/Download24";
import Button from "@ausbom/button";

function mapStateToProps(state) {
  return {
    results: state.results,
    calculate: state.lastSearch.calculate,
    filename: `${state.lastSearch.calculate}_${state.lastSearch.startYear}-${state.lastSearch.startMonth}_${state.lastSearch.endYear}-${state.lastSearch.endMonth}_StackedBarChart.png`
  };
}

function StackedBarChart(props) {
  StackedBarChart.propTypes = {
    results: PropTypes.object,
    calculate: PropTypes.string,
    filename: PropTypes.string
  };

  var operationallyArray = [];
  var farArray = [];
  var podArray = [];
  // Create the results to be plotted.
  for (var key in props.results) {
    // Check to see if results exist and change to two decimals if they do, else leave as null.
    if (props.results[key]["operationally_correct"]) {
      operationallyArray.push(props.results[key]["operationally_correct"].toFixed(2));
    } else {
      operationallyArray.push(props.results[key]["operationally_correct"]);
    }
    if (props.results[key]["pod"]) {
      farArray.push((props.results[key]["far"] * -1).toFixed(2));
    } else {
      farArray.push((props.results[key]["far"] * -1));
    }
    if (props.results[key]["pod"]) {
      podArray.push(props.results[key]["pod"].toFixed(2));
    } else {
      podArray.push(props.results[key]["pod"]);
    }
  }

  const chartOptions = {
    maintainAspectRatio: true,
    title: {
      display: true,
      text: "Tension of PoD VS Negated FAR Against Operationally Correct"
    },
    scales: {
      yAxes: [
        {
          ticks: {
            max: 1,
            min: -1
          },
          display: true,
          position: "left",
          id: "y-axis-1",
          labels: {
            show: true
          },
          scaleLabel: {
            display: true,
            labelString: "Negated FAR and PoD"
          },
          stacked: true
        },
        {
          ticks: {
            max: 100,
            min: 0
          },
          display: true,
          position: "right",
          id: "y-axis-2",
          scaleLabel: {
            display: true,
            labelString: "Operationallty Correct"
          },
          stacked: false
        }
      ],
      xAxes: [{ stacked: true }]
    },
    tooltips: {
      mode: "label"
    }
  };

  const chartData = {
    labels: Object.keys(props.results),
    datasets: [
      {
        label: "FAR",
        data: farArray,
        type: "bar",
        backgroundColor: "#DA394D",
        yAxisID: "y-axis-1",
        stack: "0"
      },
      {
        label: "PoD",
        data: podArray,
        type: "bar",
        backgroundColor: "#6642C0",
        yAxisID: "y-axis-1",
        stack: "0"
      },
      {
        label: "Operationally Correct",
        data: operationallyArray,
        type: "bar",
        backgroundColor: "#2461E5",
        yAxisID: "y-axis-2",
        stack: "1"
      }
    ]
  };

  /**
   * @desc Take a snapshot of the canvas that the char is displayed on 
   * and make a file of it for the user to download.
   */
  function downloadPng() {
    var stackedBarChartCanvas = document.querySelector("#stackedBarChart");
    var ctx = stackedBarChartCanvas.getContext("2d");
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "#f9f9fa";
    ctx.fillRect(0, 0, stackedBarChartCanvas.width, stackedBarChartCanvas.height);
    if (window.navigator.msSaveOrOpenBlob) {
      var blob = stackedBarChartCanvas.msToBlob();
      window.navigator.msSaveBlob(blob, props.filename);
    } else {
      stackedBarChartCanvas.toBlob(function (blob) {
        saveAs(blob, props.filename);
      });
    }
    ctx.globalCompositeOperation = "source-over";
  }

  return (
    <>
      <Button data-testid='downloadChart' id="downloadButton" style={{ float: "right" }} onClick={downloadPng} small variant="secondary">
        Download .png <Download24 primaryColor="#2461E5" secondaryColor="#2461E5" />
      </Button>

      <Bar id="stackedBarChart" data={chartData} options={chartOptions} />
    </>
  );
}

const StackedBarChartComponent = connect(mapStateToProps)(StackedBarChart);
export default StackedBarChartComponent;
