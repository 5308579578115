import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Header from './components/header/header-component'
import './index.css';

class App extends Component {



  render() {


    return (

      <Header />

    );
  }

}

export default withRouter(App);

