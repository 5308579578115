import React from 'react';
import { Auth, API } from "aws-amplify";
import PasswordForm from "./forms/password-form-component"
import { Table } from 'react-bootstrap';
import { FaUserAlt, FaEdit, FaMobileAlt, FaLock, FaLocationArrow, FaUsers, FaTimes } from "react-icons/fa";
import { MdEmail } from "react-icons/md"
import { createBrowserHistory } from "history";

// Email phone locations
class UserProfile extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userData: {},
            userLocations: []
        };
    }

    /**
     * @desc Check if user is authed, if not, send them back to login page.
     * If successful, load their locations to display on the page.
     */
    async componentDidMount() {
        let jwtToken
        try {
            const sessionInfo = await Auth.currentSession()
            this.setState({ userData: sessionInfo.getIdToken().payload })
            jwtToken = sessionInfo.getIdToken().getJwtToken()
        } catch (error) {
            const customHistory = createBrowserHistory();
            customHistory.replace('/#/login')
            customHistory.go()
        }
        try {
            const header = {
                headers: { Authorization: `Bearer ${jwtToken}` }
            }
            let data = await API.get("groups", `/locations`, header);
            this.setState({ userLocations: data.join(', ') })
        } catch (error) { console.log(error) }
    }

    // After 5 seconds, hide the success alert.
    /**
     * @desc Timer for success message to hide after 5 seconds.
     * @param {*} id used to determine what field was edited.
     */
    timer = (id) => {
        setTimeout(
            function () {
                this.setState({ [id + "Success"]: false });
            }.bind(this), 5000
        );
    }

    /**
     * @desc Handle the edit change for the selected field.
     * @param {*} event to determine what item was edited and what is the new value.
     */
    handleEdit = (event) => {
        const id = event.target.id
        this.setState((prevState) => { return { [id]: !prevState[id] } })
    }

    /**
     * @desc Handle the successful change of the selected field.
     * @param {*} id to determine the field that has been edited.
     */
    handleChange = (id) => {
        this.setState({ [id]: false })
        this.setState({ [id + "Success"]: true })
        this.timer(id)
    }

    render() {
        return (
            <>
                <div className="container main-container" style={{ maxWidth: 750, marginTop: 15 }}>
                    <h4>User Profile</h4>
                    <Table striped hover className="userProfileTable mt-3">
                        <tbody>
                            <tr>
                                <td><FaUserAlt /></td>
                                <td>Username</td>
                                <td colSpan={2}>{this.state.userData['cognito:username']}</td>
                            </tr>
                            <tr>
                                <td><FaUsers /></td>
                                <td>Group</td>
                                <td colSpan={2}>{this.state.userData["cognito:groups"]}</td>
                            </tr>
                            <tr>
                                <td><MdEmail /></td>
                                <td>Email</td>
                                <td>
                                    {this.state.userData.email}

                                </td><td></td>
                            </tr>
                            <tr>
                                <td><FaMobileAlt /></td>
                                <td>Mobile</td>
                                <td>
                                    {this.state.userData.phone_number}
                                </td><td></td>
                            </tr>
                            <tr>
                                <td><FaLock /></td>
                                <td>Password</td>
                                <td>
                                    {this.state.editPassword ?
                                        <PasswordForm formId="editPassword" handleSuccessfulUpdate={() => this.handleChange("editPassword")} />
                                        :
                                        <>
                                            &#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;
                                            {this.state.editPasswordSuccess && <span style={{ color: "green", float: "right" }}>Updated!</span>}
                                        </>
                                    }
                                </td>
                                <td data-testid='editPassword' id="editPassword" onClick={this.handleEdit}>{this.state.editPassword ? <FaTimes size="1.5em" /> : <FaEdit size="1.5em" />}</td>
                            </tr>
                            <tr>
                                <td><FaLocationArrow /></td>
                                <td>Locations</td>
                                <td colSpan={2}>{this.state.userLocations}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }
}
export default UserProfile