import React from "react";
import { connect } from "react-redux";
import ReactJson from "react-json-view";
import Download24 from "@ausbom/icon/lib/icons/system/Download24";
import PropTypes from "prop-types";
import { Auth, API } from "aws-amplify";
import Button from "@ausbom/button";
import { addErrors } from "../../../../actions/index";
import { createBrowserHistory } from "history";
import Tooltip, { TooltipButton } from '@ausbom/tooltip'
function mapDispatchToProps(dispatch) {
  return {
    addErrors: errors => dispatch(addErrors(errors))
  };
}

function mapStateToProps(state) {
  return {
    locations: state.currentSearch.locations,
    results: state.results,
    calculate: state.lastSearch.calculate,
    filename: `${state.lastSearch.calculate}_${state.lastSearch.startYear}-${state.lastSearch.startMonth}_${state.lastSearch.endYear}-${state.lastSearch.endMonth}.json`,
    comparison: state.currentSearch.comparison,
    eCondition: state.currentSearch.eCondition,
    hourRange: state.currentSearch.hourRange,
    startMonth: state.currentSearch.startMonth,
    endMonth: state.currentSearch.endMonth,
    startYear: state.currentSearch.startYear,
    endYear: state.currentSearch.endYear,
    startDay: state.currentSearch.startDay,
    endDay: state.currentSearch.endDay,
    errors: state.errors,
  };
}

function ExportJson(props) {
  ExportJson.propTypes = {
    results: PropTypes.object,
    calculate: PropTypes.string,
    filename: PropTypes.string,
    locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    comparison: PropTypes.string,
    eCondition: PropTypes.string,
    hourRange: PropTypes.string,
    startYear: PropTypes.number,
    startMonth: PropTypes.number,
    startDay: PropTypes.number,
    endYear: PropTypes.number,
    endMonth: PropTypes.number,
    endDay: PropTypes.number,
    periodOption: PropTypes.string,
    addErrors: PropTypes.func,
    errors: PropTypes.array
  };

  /**
   * @desc Downloads the results for the user that are being displayed in graphs and tables.
   */
  const downloadJson = async () => {
    const blob = new Blob([JSON.stringify(props.results, null, 2)], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = props.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * @desc Downloads the raw file data from AWS S3 before calculations are done to it.
   * Check if the user is authed, if not, push them to the login page.
   * Create the API call and have the data put into a file and activate a download for the user.
   */
  const downloadJsonRaw = async () => {
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (e) {
      if (e !== 'No current user') {
        const customHistory = createBrowserHistory();
        customHistory.replace('/#/login')
        customHistory.go()
      }
    }
    const header = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };
    await API.get("groups", `/export/${props.locations}/${props.startYear}/${props.startMonth}/${props.endYear}/${props.endMonth}/${props.comparison}`, header)
      .then(async response => {
        const blob = new Blob([JSON.stringify(response)], { type: "application/json" });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.download = props.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(error => {
        console.log(error)
      });
  }

  return (
    <><Tooltip allyId='default' renderTrigger={({getButtonProps}) => <TooltipButton style={{ float: "right", marginLeft: '5px' }} label='example' {...getButtonProps()}/>}>Large date ranges may cause issues when downloading raw data. Please download raw data in smaller date ranges to avoid issues.</Tooltip>
      <Button data-testid='exportRawTest' id="downloadButton" style={{ float: "right" }} onClick={downloadJsonRaw} small variant="secondary">
        Export Raw Data <Download24 primaryColor="#2461E5" secondaryColor="#2461E5" />
      </Button>
      <Button className='primaryButton' data-testid='exportTest' id="downloadButton" style={{ float: "right" }} onClick={downloadJson} small variant="secondary">
        Export <Download24 primaryColor="#2461E5" secondaryColor="#2461E5" />
      </Button>
      <>
        <ReactJson src={props.results} collapsed={0} name={false} sortKeys={true} style={{ width: '100%', float: "right", height: "490px", overflowY: "auto" }} />
      </>
    </>
  );
}

const ExportJsonComponent = connect(mapStateToProps, mapDispatchToProps)(ExportJson);
export default ExportJsonComponent;
