import React, { Component } from 'react';
import { FaExclamationTriangle } from "react-icons/fa";

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        hasError: false,
        error: ""
     };
    }
  
    static getDerivedStateFromError(error) {
      return { 
        hasError: true,
        error
     };
    }
  
    componentDidCatch(error, errorInfo) {
      console.log({error, errorInfo});
    }
  
    render() {
      if (this.state.hasError) {
        return (
        <div  style={{color:"red"}}>
                <div style={{ fontSize:"24px"} }>
                    <FaExclamationTriangle></FaExclamationTriangle>
                    <span>&nbsp;Unexpected error occured - </span>
                    <span style={{ fontSize:"18px", lineHeight:"1.5"}}>Please try again</span>
                </div>
                { this.state.error && this.state.error.message &&  (
                <div style={{ fontSize:"16px"} }>
                    {this.state.error.message}
                </div>
                )}
            
        </div>
        )
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;