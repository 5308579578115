import { ADD_TOGGLE, ADD_LOCATIONS, ADD_TIME_PERIOD, ADD_TITLE, ADD_SEARCHES, ADD_ALL_SEARCHES, ADD_COMPARISON, ADD_E_CONDITION, ADD_HOUR_RANGE, ADD_LOCATIONS_VALID, ADD_RESULTS, ANNOUNCE_502_ERROR, ADD_ERRORS, ADD_START_MONTH, ADD_START_YEAR, ADD_END_MONTH, ADD_END_YEAR, ADD_PERIOD_OPTION, ADD_START_DAY, ADD_END_DAY, ADD_CALCULATE, ADD_GROUP_ID, ADD_GROUPS, ADD_ALL_GROUPS, ADD_SEARCH_LOADING, ADD_LAST_SEARCH, ADD_OUTPUT, ADD_SHOW_SUCCESS, ADD_SUCCESS_MSG, ADD_EMAILS, ADD_EDIT_SEARCH, ADD_EDIT_POPUP } from "../constants/action-types";
import React from "react";
import TabularData from "../components/results/tabs/tabular-data/tabular-data-component";
import BarChart from "../components/results/tabs/bar-chart/bar-chart-component";
import StackedBarChart from "../components/results/tabs/stacked-bar-chart/stacked-bar-chart-component";
import ScatterPlot from "../components/results/tabs/scatter-plot/scatter-plot-component";
import ExportJson from "../components/results/tabs/export-json/export-json-component";

const initialState = {
  OUTPUT_TITLE_MAP: {
    tableDataOption: "Tablular",
    barGraphOption: "Bar chart",
    stackedBarGraphOption: "Stacked bar chart",
    scatterPlotOption: "Scatter plot",
    exportJsonOption: "JSON"
  },
  showSuccess: false,
  successMsg: "",
  currentSearch: {
    locations: [],
    timePeriod: "Current year",
    comparison: "VERIFICATION",
    eCondition: "visibility",
    hourRange: "full_validity",
    startMonth: 1,
    startYear: new Date().getFullYear(),
    endMonth: new Date().getMonth() + 1,
    endYear: new Date().getFullYear(),
    periodOption: "default",
    startDay: 1,
    endDay: 28,
    calculate: "monthly",
    outputs: {
      tabularOption: {
        title: "Tabular",
        checked: true,
        component: <TabularData />
      },
      barChartOption: {
        title: "Bar chart",
        checked: false,
        component: <BarChart />
      },
      stackedBarChartOption: {
        title: "Stacked bar chart",
        checked: false,
        component: <StackedBarChart />
      },
      scatterPlotOption: {
        title: "Scatter plot",
        checked: false,
        component: <ScatterPlot />
      },
      exportJsonOption: {
        title: "JSON",
        checked: false,
        component: <ExportJson />
      }
    }
  },
  lastSearch: {},
  editSearch: {},
  title: "",
  searches: [],
  locationsValid: false,
  results: {},
  errors: [],
  toggle: false,
  groupId: "",
  groups: [],
  allGroups: [],
  allSearches: [],
  emails: [],
  editPopup: false
};
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_LOCATIONS:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, locations: action.payload.locations }
      };
    case ADD_TIME_PERIOD:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, timePeriod: action.payload.timePeriod }
      };
    case ADD_TITLE:
      return {
        ...state,
        title: action.payload.title
      };
    case ADD_SEARCHES:
      return {
        ...state,
        searches: action.payload.searches
      };
    case ADD_ALL_SEARCHES:
      return {
        ...state,
        allSearches: action.payload.allSearches
      };
    case ADD_GROUPS:
      return {
        ...state,
        groups: action.payload.groups
      };
    case ADD_ALL_GROUPS:
      return {
        ...state,
        allGroups: action.payload.allGroups
      };
    case ADD_COMPARISON:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, comparison: action.payload.comparison }
      };
    case ADD_E_CONDITION:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, eCondition: action.payload.eCondition }
      };
    case ADD_HOUR_RANGE:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, hourRange: action.payload.hourRange }
      };
    case ADD_LOCATIONS_VALID:
      return {
        ...state,
        locationsValid: action.payload.locationsValid
      };
    case ADD_RESULTS:
      return {
        ...state,
        results: action.payload.results
      };
     case ANNOUNCE_502_ERROR:
      return {
        ...state,
        announce502Error: action.payload.error
      };
    case ADD_ERRORS:
      return {
        ...state,
        errors: action.payload.errors
      };
    case ADD_START_MONTH:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, startMonth: action.payload.startMonth }
      };
    case ADD_START_YEAR:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, startYear: action.payload.startYear }
      };
    case ADD_END_MONTH:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, endMonth: action.payload.endMonth }
      };
    case ADD_END_YEAR:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, endYear: action.payload.endYear }
      };
    case ADD_PERIOD_OPTION:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, periodOption: action.payload.periodOption }
      };
    case ADD_START_DAY:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, startDay: action.payload.startDay }
      };
    case ADD_END_DAY:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, endDay: action.payload.endDay }
      };
    case ADD_CALCULATE:
      return {
        ...state,
        currentSearch: { ...state.currentSearch, calculate: action.payload.calculate }
      };
    case ADD_TOGGLE:
      return {
        ...state,
        toggle: action.payload.toggle
      };
    case ADD_OUTPUT:
      return {
        ...state,
        currentSearch: {
          ...state.currentSearch,
          outputs: {
            ...state.currentSearch.outputs,
            [action.payload.key]: {
              ...state.currentSearch.outputs[action.payload.key],
              checked: action.payload.value
            }
          }
        }
      };
    case ADD_GROUP_ID:
      return {
        ...state,
        groupId: action.payload.groupId
      };
    case ADD_SEARCH_LOADING:
      return {
        ...state,
        searchLoading: action.payload.searchLoading
      };
    case ADD_LAST_SEARCH:
      return {
        ...state,
        lastSearch: action.payload.lastSearch
      };
    case ADD_SHOW_SUCCESS:
      return {
        ...state,
        showSuccess: action.payload.showSuccess
      };
    case ADD_SUCCESS_MSG:
      return {
        ...state,
        successMsg: action.payload.successMsg
      };
    case ADD_EMAILS:
      return {
        ...state,
        emails: action.payload.emails
      };
      case ADD_EDIT_SEARCH:
      return {
        ...state,
        editSearch: action.payload.editSearch
      };
      case ADD_EDIT_POPUP:
      return {
        ...state,
        editPopup: action.payload.editPopup
      };
    default:
      return state;
  }
}
export default rootReducer;
