/*
*/
import React from "react";
import { connect } from "react-redux";
import { addLocation, searchData, addResults, addShowSuccess, addSuccessMsg, addErrors, addEmails } from "../../../../../../actions/index";
import Modal from "react-bootstrap/Modal";
import { Auth } from "aws-amplify";
import Popup from "../../../../../components/Popup/Popup";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import DropDown from "../../../../../components/DropDown/DropDown";
import Button from "@ausbom/button";

// Handle props used for this component.
function mapDispatchToProps(dispatch) {
  return {
    addLocation: locations => dispatch(addLocation(locations)),
    addErrors: errors => dispatch(addErrors(errors)),
    searchData: searches => dispatch(searchData(searches)),
    addResults: results => dispatch(addResults(results)),
    addShowSuccess: showSuccess => dispatch(addShowSuccess(showSuccess)),
    addSuccessMsg: successMsg => dispatch(addSuccessMsg(successMsg)),
    addEmails: emails => dispatch(addEmails(emails))
  };
}

const mapStateToProps = state => {
  return {
    timePeriod: state.currentSearch.timePeriod,
    locations: state.currentSearch.locations,
    searches: state.searches,
    comparison: state.currentSearch.comparison,
    eCondition: state.currentSearch.eCondition,
    hourRange: state.currentSearch.hourRange,
    locationsValid: state.locationsValid,
    results: state.results,
    errors: state.errors,
    calculate: state.currentSearch.calculate,
    outputs: state.currentSearch.outputs,
    emails: state.emails
  };
};

class EmailForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      isLoading: false,
      hideTitleAlert: true,
      hideSuccess: true,
      hideValidForm: true,
      errorMessage: "",
      frequencyOptions: { 0: { value: "weekly", display: "Weekly", disabled: false }, 1: { value: "monthly", display: "Monthly", disabled: false }, 2: { value: "quarterly", display: "Quarterly", disabled: false } },
      frequency: "weekly",
      email: "",
      emails: [],
      invalidOutputs: false,
      calculatedMessage: ''
    };
    this.handleDropdownSelect = this.handleDropdownSelect.bind(this)

    EmailForm.propTypes = {
      locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      searches: PropTypes.array,
      comparison: PropTypes.string,
      eCondition: PropTypes.string,
      hourRange: PropTypes.string,
      results: PropTypes.object,
      errors: PropTypes.array,
      locationsValid: PropTypes.bool,
      outputs: PropTypes.object,
      calculate: PropTypes.string,
      addLocation: PropTypes.func,
      searchData: PropTypes.func,
      addResults: PropTypes.func,
      addShowSuccess: PropTypes.func,
      addSuccessMsg: PropTypes.func,
      addErrors: PropTypes.func,
      addEmails: PropTypes.func
    };
  }

  componentDidMount() {
    let newOptions;
    if (this.props.calculate === 'monthly') {
      newOptions = { 0: { value: "weekly", display: "Weekly", disabled: true }, 1: { value: "monthly", display: "Monthly", disabled: false }, 2: { value: "quarterly", display: "Quarterly", disabled: false } }
      this.setState({frequency: "monthly"})
    } else if (this.props.calculate === 'daily') {
      newOptions = { 0: { value: "weekly", display: "Weekly", disabled: false }, 1: { value: "monthly", display: "Monthly", disabled: false }, 2: { value: "quarterly", display: "Quarterly", disabled: false } }
    }
    this.setState({ frequencyOptions: newOptions })

  }

  /**
  * @desc Check if an output is selected or not to determine if email button needs to be disabled.
  * @param {*} prevProps used to compare against current props to detect change.
  */
  async componentDidUpdate(prevProps) {
    if (this.props.calculate !== prevProps.calculate) {
      this.componentDidMount()
    }
    if (this.props.outputs !== prevProps.outputs) {
      for (const key in this.props.outputs) {
        if (this.props.outputs[key].checked === true) {
          await this.setState({ invalidOutputs: false })
          break;
        } else {
          await this.setState({ invalidOutputs: true })
        }
      }
    }
  }

  /**
   * @desc display modal on click.
   */
  saveEmailPopup = () => {
    this.handleModel("show", true);
  };

  /**
   * @desc Get user auth info for jwtToken and check if user has entered a valid email address, if not display error.
   * Build API call with form prop info for what the user will recieve.
   * Call API, possible errors will be function error and not a valid location for this user.
   * If successful an alert will display. Then the users emails will update.
   */
  saveEmail = async () => {
    const sessionInfo = await Auth.currentSession();
    const jwtToken = sessionInfo.getIdToken().getJwtToken()
    if (this.state.email === "" || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) === false) {
      this.setState({ hideTitleAlert: false });
    } else {
      this.setState({ hideTitleAlert: true });
      this.setState({ isLoading: true });
      const outputsArray = Object.keys(this.props.outputs).filter(output => this.props.outputs[output].checked === true);
      const header = {
        headers: { Authorization: `Bearer ${jwtToken}` },
        body: {
          email: `${this.state.email}`,
          locations: `${this.props.locations}`,
          comparison: `${this.props.comparison}`,
          e_conditions: `${this.props.eCondition}`,
          hourRange: `${this.props.hourRange}`,
          calculate: `${this.props.calculate}`,
          outputs: `${outputsArray}`,
          frequency: `${this.state.frequency}`
        }
      };
      await API.post("groups", `/email`, header)
        .then(response => {
          this.handlePopupAlerts("hideSuccess", false, "successMessage", "Email successfully saved!");
          this.timer();
          this.props.addSuccessMsg({
            successMsg: "Email successfully saved."
          });
          this.props.addShowSuccess({ showSuccess: true });
        })
        .catch(error => {
          if (typeof error !== 'string') {
            this.props.addErrors({ errors: [error.response.data] })
          } else {
            this.props.addErrors({ errors: [error] })
          }
        });
      try {
        let data = await API.get("groups", "/email", header);
        this.props.addEmails({ emails: data });
      } catch (error) {
        console.log(error)
        this.handlePopupAlerts("hideValidForm", false, "errorMessage", "There was an error loading your emails. Please try again or contact our support.");
        this.timer();
      }
      this.setState({ isLoading: false });
      this.handleModel("show", false);
    }
  };

  // 5 second timer
  /**
   * @desc After 5 seconds, the selected alerts will be hidden.
   */
  timer = () => {
    setTimeout(
      function () {
        this.setState({ hideSuccess: true });
        this.setState({ hideValidForm: true });
      }.bind(this),
      10000
    );
  };

  /**
   * @desc To determine what email frequency has been selected by the user.
   * @param {event} event what option was selected from the dropdown.
   */
  async handleDropdownSelect(event) {
    await this.setState({ frequency: event.target.value });
    console.log(this.state.frequency);
  };

  /**
   * @desc Accepts model name and true or false for closing it or opening it. 
   * @param {string} variable modal name.
   * @param {boolean} bool true or false.
   */
  handleModel = (variable, bool) => {
    this.setState({ hideTitleAlert: true });
    this.setState({ [variable]: bool });
  };

  /**
   * @desc User to determine what popup alerts are displayed and with what information.
   * @param {string} variable what alert is to be down.
   * @param {boolean} bool true or false.
   * @param {string} textVariable What type of alert is to be displayed.
   * @param {string} textMessage What the text of the alert will be.
   */
  handlePopupAlerts = (variable, bool, textVariable, textMessage) => {
    this.setState({ [textVariable]: textMessage });
    this.setState({ [variable]: bool });
  };

  /**
   * @desc Handle the change of the email input to set the state of what the user has entered.
   * @param {event} event Used to get the email input value.
   */
  handleChange = async event => {
    this.setState({ email: event.target.value });
  };

  /**
  * @desc determines if valid input and output has been selected, if not, disable email button.
  */
  isDisabled() {
    if (this.props.locationsValid === true && this.state.invalidOutputs === false && this.props.calculate !== 'yearly') {
      return false
    } else {
      return true
    }
  }

  render() {
    return (
      <>
        <Button className='primaryButton' data-testid="test" onClick={this.saveEmailPopup} disabled={this.isDisabled()} small>
          Email
        </Button>

        <Modal size="lg" show={this.state.show} onHide={() => this.handleModel("show", false)}>
          <Modal.Header closeButton>
            <Modal.Title>Current email details: </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-hover" id="data-requirements">
              <thead className="thead-light">
                <tr>
                  <th className="tableHead">Variable</th>
                  <th className="tableHead">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Locations: </td>
                  <td className="locationsPara">
                    {Object.keys(this.props.locations).map((dg, i) => (
                      <span key={i} value={this.props.locations[dg]}>
                        {this.props.locations[dg]},
                      </span>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td>Calculated: </td>
                  <td>{this.props.calculate}</td>
                </tr>
                <tr>
                  <td>Comparison: </td>
                  <td>{this.props.comparison}</td>
                </tr>
                <tr>
                  <td>Enviromental condition: </td>
                  <td>{this.props.eCondition === "weather" ?  "thunderstorms" : this.props.eCondition}</td>
                </tr>
                <tr>
                  <td>Time series: </td>
                  <td>{this.props.hourRange}</td>
                </tr>
                <tr>
                  <td>Outputs:</td>
                  <td>Tabular
                    {/* {Object.keys(this.props.outputs)
                      .filter(output => this.props.outputs[output].checked === true)
                      .map(output => this.props.outputs[output].title)
                      .join(", ")} */}
                  </td>
                </tr>
                <tr>
                  <td>Email frequency: </td>
                  <td>
                    <DropDown dropDownType="otherParams" selectGroupId="frequency" selectRef="compInput" optionItems={this.state.frequencyOptions} handleDropSelect={this.handleDropdownSelect} />
                  </td>
                </tr>
                <tr>
                  <td>Enter email: </td>
                  <td>
                    <input autoComplete="off" className="formInput inputWidthMedium" type="text" name="locations" placeholder="Please enter a valid email." id="title-text" value={this.state.email} onChange={this.handleChange} />
                  </td>
                </tr>
              </tbody>
            </table>
            {this.state.hideTitleAlert === false && <Popup divClassName="errorDialog" textClassName="errorText" alertText="Please enter a valid email address." />}
          </Modal.Body>
          <Modal.Footer>
            <Button data-testid="emailButton" onClick={this.saveEmail} disabled={!this.state.email} small>
              Confirm email
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const EmailFormPopup = connect(mapStateToProps, mapDispatchToProps)(EmailForm);

export default EmailFormPopup;
