import React from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import saveAs from "file-saver";
import Download24 from "@ausbom/icon/lib/icons/system/Download24";
import Button from "@ausbom/button";

function mapStateToProps(state) {
  return {
    results: state.results,
    startMonth: state.lastSearch.startMonth,
    endMonth: state.lastSearch.endMonth,
    startYear: state.lastSearch.startYear,
    endYear: state.lastSearch.endYear,
    locations: state.lastSearch.locations,
    eCondition: state.lastSearch.eCondition,
    calculate: state.lastSearch.calculate,
    filename: `${state.lastSearch.calculate}_${state.lastSearch.startYear}-${state.lastSearch.startMonth}_${state.lastSearch.endYear}-${state.lastSearch.endMonth}_BarChart.png`
  };
}

function BarChart(props) {
  BarChart.propTypes = {
    results: PropTypes.object,
    startMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    startYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    endMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    endYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    eCondition: PropTypes.string,
    calculate: PropTypes.string,
    filename: PropTypes.string
  };

    // Each value must be taken from the months/years and put together as their own array to plot.
    var operationallyArray = [];
    var operationallyAvg = 0;
    var operationallyAvgArray = [];
    var hoursObservedArray = [];
    var farArray = [];
    var farAvg = 0;
    var farAvgArray = [];
    var podAvg = 0;
    var podArray = [];
    var podAvgArray = [];
    var faleArray = [];

    for (var key in props.results) {
      // If value exists, change value to be a percentage and round to two decimals.
      // Some values also need an average to be displayed so calculate those as well.
      if (props.results[key]["operationally_correct"]) {
        operationallyArray.push((props.results[key]["operationally_correct"] / 100).toFixed(2));
      } else {
        operationallyArray.push((props.results[key]["operationally_correct"] / 100));
      }
      operationallyAvg += props.results[key]["operationally_correct"] / 100;
      hoursObservedArray.push(props.results[key]["hours_observed_below_minima"]);
      if (props.results[key]["far"]) {
        farArray.push(props.results[key]["far"].toFixed(2));
      } else {
        farArray.push(props.results[key]["far"]);
      }
      farAvg += props.results[key]["far"];
      if (props.results[key]["pod"]) {
        podArray.push(props.results[key]["pod"].toFixed(2));
      } else {
        podArray.push(props.results[key]["pod"]);
      }
      podAvg += props.results[key]["pod"];
      faleArray.push(props.results[key]["fale_alarm_rate"]);
    }
    // Calculate the averages to be displayed.
    operationallyAvg = (operationallyAvg / operationallyArray.length).toFixed(2);
    farAvg = (farAvg / farArray.length).toFixed(2);
    podAvg = (podAvg / podArray.length).toFixed(2);
    // Create an object for the averages so that each month has a plot.
    for (var result in props.results) {
      console.log(result)
      operationallyAvgArray.push(operationallyAvg);
      farAvgArray.push(farAvg);
      podAvgArray.push(podAvg);
    }
  const environmentalCondition = props.eCondition === "weather" ?  "thunderstorms" : props.eCondition;
  const chartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    title: {
      display: true,
      text: `Data for ${props.startMonth}/${props.startYear} - ${props.endMonth}/${props.endYear} for locations ${props.locations} for ${environmentalCondition} environmental condition`
    },
    scales: {
      yAxes: [
        {
          ticks: {
            max: 1,
            min: 0
          },
          scaleLabel: {
            display: true,
            labelString: "Normalised Operationallty Correct"
          },
          position: "left",
          id: "y-axis-1"
        },
        {
          ticks: {
            min: 0
          },
          display: true,
          position: "right",
          id: "y-axis-2",
          labels: {
            show: true
          },
          scaleLabel: {
            display: true,
            labelString: "Hours Observed Below Minima"
          }
        }
      ]
    },
    tooltips: {
      mode: "label"
    }
  };
  const chartData = {
    labels: Object.keys(props.results),
    datasets: [
      {
        label: "Op. Correct Average",
        data: operationallyAvgArray,
        type: "line",
        backgroundColor: "#e5e5e5",
        fill: false,
        borderColor: "#2461E5",
        yAxisID: "y-axis-1"
      },
      {
        label: "FAR Average",
        data: farAvgArray,
        type: "line",
        backgroundColor: "#e5e5e5",
        fill: false,
        borderColor: "#DA394D",
        yAxisID: "y-axis-1"
      },
      {
        label: "PoD Average",
        data: podAvgArray,
        type: "line",
        backgroundColor: "#e5e5e5",
        fill: false,
        borderColor: "#6642C0",
        yAxisID: "y-axis-1"
      },
      {
        label: "Operationally Correct",
        data: operationallyArray,
        type: "bar",
        backgroundColor: "#2461E5",
        yAxisID: "y-axis-1"
      },
      {
        label: "FAR",
        data: farArray,
        type: "bar",
        backgroundColor: "#DA394D",
        yAxisID: "y-axis-1"
      },
      {
        label: "PoD",
        data: podArray,
        type: "bar",
        backgroundColor: "#6642C0",
        yAxisID: "y-axis-1"
      },
      {
        label: "Hours Observed Below Minima",
        data: hoursObservedArray,
        type: "bar",
        backgroundColor: "#00A087",
        yAxisID: "y-axis-2"
      }
    ]
  };

  /**
   * @desc Take a snapshot of the canvas that the char is displayed on 
   * and make a file of it for the user to download.
   */
  function downloadPng() {
    var barChartCanvas = document.querySelector("#barChart");
    var ctx = barChartCanvas.getContext("2d");
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "#f9f9fa";
    ctx.fillRect(0, 0, barChartCanvas.width, barChartCanvas.height);
    if (window.navigator.msSaveOrOpenBlob) {
      var blob = barChartCanvas.msToBlob();
      window.navigator.msSaveBlob(blob, props.filename);
    } else {
      barChartCanvas.toBlob(function (blob) {
        saveAs(blob, props.filename);
      });
    }
    ctx.globalCompositeOperation = "source-over";
  }

  return (
    <>
      <Button data-testid='downloadChart' id="downloadButton" style={{ float: "right" }} onClick={downloadPng} small variant="secondary">
        Download .png <Download24 primaryColor="#2461E5" secondaryColor="#2461E5" />
      </Button>
      <Bar id="barChart" data={chartData} options={chartOptions} />
    </>
  );
}

const BarChartComponent = connect(mapStateToProps)(BarChart);
export default BarChartComponent;
