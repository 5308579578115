import React from "react";
import Modal from "react-bootstrap/Modal";
import ReactTable from "react-table";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { addTimePeriod, addLocation, searchData, allSearchData, addComparison, addECondition, addHourRange, addCalculate, addToggle, addLocationsValid, addOutput, addEmails } from "../../../../../actions/index";
import PropTypes from "prop-types";
import { FaTrashAlt } from "react-icons/fa";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import Button from "@ausbom/button";
import { createBrowserHistory } from "history";

// Handle props used for this component.
function mapDispatchToProps(dispatch) {
  return {
    addTimePeriod: timePeriod => dispatch(addTimePeriod(timePeriod)),
    addLocation: locations => dispatch(addLocation(locations)),
    searchData: searches => dispatch(searchData(searches)),
    allSearchData: allSearches => dispatch(allSearchData(allSearches)),
    addComparison: comparison => dispatch(addComparison(comparison)),
    addECondition: eCondition => dispatch(addECondition(eCondition)),
    addHourRange: hourRange => dispatch(addHourRange(hourRange)),
    addCalculate: calculate => dispatch(addCalculate(calculate)),
    addToggle: toggle => dispatch(addToggle(toggle)),
    addLocationsValid: locationsValid => dispatch(addLocationsValid(locationsValid)),
    addOutput: output => dispatch(addOutput(output)),
    addEmails: emails => dispatch(addEmails(emails))
  };
}

const mapStateToProps = state => {
  return {
    searches: state.searches,
    toggle: state.toggle,
    outputs: state.outputs,
    allSearches: state.allSearches,
    emails: state.emails
  };
};

class Emails extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      selectedRow: "",
      isLoading: false,
      hideSuccess: true,
      emails: [],
      outputs: []
    };

    Emails.propTypes = {
      searches: PropTypes.array,
      allSearches: PropTypes.array,
      toggle: PropTypes.bool,
      outputs: PropTypes.object,
      addLocation: PropTypes.func,
      addTimePeriod: PropTypes.func,
      addComparison: PropTypes.func,
      addECondition: PropTypes.func,
      addHourRange: PropTypes.func,
      addCalculate: PropTypes.func,
      addToggle: PropTypes.func,
      addLocationsValid: PropTypes.func,
      addOutput: PropTypes.func,
      searchData: PropTypes.func,
      addEmails: PropTypes.func,
      emails: PropTypes.array
    };

    this.render = this.render.bind(this);
    this.deleteEmail = this.deleteEmail.bind(this);
    this.deleteEmailPopup = this.deleteEmailPopup.bind(this);
  }

  /**
  * @desc Check if user is authed, if not send to login page.
  * If authed then retrieve the users saved emails.
  */
  async componentDidMount() {
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (error) {
      const customHistory = createBrowserHistory();
      customHistory.replace('/#/login')
      customHistory.go()
    }
    const header = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };
    try {
      let data = await API.get("groups", "/email", header);
      this.props.addEmails({ emails: data });
    } catch (error) {
      this.handlePopupAlerts("hideValidForm", false, "errorMessage", "There was an error loading your saved searches. Please try again or contact our support.");
      this.timer();
    }
  }

  /**
   * @desc display the delete email popup modal.
   * @param {*} row use to determine what row the button was clicked on.
   */
  async deleteEmailPopup(row) {
    console.log(row._index)
    await this.setState({ selectedRow: row._index });
    this.handleModel("show", true);
  }

  /**
   * @desc Get the jwtToken from the authed user, if not authed, send user to login page.
   * Get id to determine what row is being deleted and the user group assigned to the search.
   * Build the API call and send off delete request.
   * @todo display success and error alerts.
   */
  async deleteEmail() {
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (error) {
      const customHistory = createBrowserHistory();
      customHistory.replace('/#/login')
      customHistory.go()
    }
    let specialId = this.props.emails[this.state.selectedRow].id;
    let otherId = this.state.selectedRow;
    this.setState({ isLoading: true });
    try {
      const header = {
        headers: { Authorization: `Bearer ${jwtToken}` }
      };
      await API.put("groups", `/email/${specialId}/delete`, header);
    } catch (error) {
      console.log(error);
    }
    this.handleModel("show", false);
    let emails = this.props.emails;
    delete emails[otherId];
    this.props.addEmails({ emails: emails });
    this.handlePopupAlerts("hideSuccess", false, "successMessage", "Search successfully deleted!");
    this.setState({ isLoading: false });
  }

  /**
   * @desc Accepts model name and true or false for closing it or opening it.
   * @param {string} variable To determine what modal to show.
   * @param {boolean} bool true or false.
   */
  handleModel(variable, bool) {
    this.setState({ showTitleAlert: true });
    this.setState({ [variable]: bool });
  }

  /**
  * @desc builds the popup alert to display for success or errors.
  * @param {string} variable the state variable to assign true or false.
  * @param {boolean} bool is assigned to the variable.
  * @param {string} textVariable The type of alert to be displayed.
  * @param {string} textMessage The text to be displayed in the alert.
  */
  handlePopupAlerts(variable, bool, textVariable, textMessage) {
    this.setState({ [textVariable]: textMessage });
    this.setState({ [variable]: bool });
  }

  fixupWeatherEcondition = (item) => {
      // add new column to dsiplay thunderstorms in the list
      item.econditions = item.e_conditions === "weather" ?  "thunderstorms" : item.e_conditions;
      return item;
  }

  render() {
    const data = this.props.emails.map(this.fixupWeatherEcondition);
    const columns = [
      {
        Header: "Frequency",
        accessor: "frequency" // String-based value accessors!
      },
      {
        Header: "Locations",
        accessor: "locations",
        style: { whiteSpace: "normal" }, // allow for words wrap inside only this cell
        Cell: ({ row }) => `${row.locations.join(", ")}.`
      },
      {
        Header: "Email",
        accessor: "email",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Comparison",
        accessor: "comparison",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Enviromental Condition",
        accessor: "econditions",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Hour Range",
        accessor: "hourRange",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <Button className="tableButton" data-testid="saveEditButton" id={row._index} onClick={() => { this.deleteEmailPopup(row) }} small>
              <FaTrashAlt style={{ pointerEvents: "none" }} />{" "}
            </Button>
          </>
        )
      }
    ];

    return (
      <div>
        <ReactTable data={data} columns={columns} defaultPageSize={10} />{" "}
        <Modal size="sm" show={this.state.show} onHide={() => this.handleModel("show", false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete email </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="locationsPara">Are you sure you want to delete this email?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.deleteEmail} small>
              Delete email
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const EmailsList = connect(mapStateToProps, mapDispatchToProps)(Emails);

export default EmailsList;
