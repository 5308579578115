import React from "react";
import { Auth } from "aws-amplify";
import { FormControl, FormLabel, FormGroup } from "react-bootstrap";
import Popup from "../../components/Popup/Popup";
import PropTypes from "prop-types";
import Button from "@ausbom/button";

class PasswordForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      isChanging: false,
      errors: []
    };

    PasswordForm.propTypes = {
      handleSuccessfulUpdate: PropTypes.func
    };
  }

  /**
   * @desc Validates the form to check that the user has entered their current password,
   * and a new password twice.
   */
  validateForm() {
    return this.state.currentPassword.length > 0 && this.state.newPassword.length > 0 && this.state.newPassword === this.state.confirmPassword;
  }

  /**
   * @desc Handles the change of each input text box.
   * @param {event} event Used to identify the text input and value entered.
   */
  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  /**
   * @desc Handles the AWS call to update the password based on what the user has entered.
   * If error, an alert will display.
   * @param {event} event 
   */
  handleUpdatePassword = async event => {
    event.preventDefault();
    this.setState({ isChanging: true });
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, this.state.currentPassword, this.state.newPassword);
      this.props.handleSuccessfulUpdate();
    } catch (e) {
      this.setState({ errors: [e.message] });
      this.setState({ isChanging: false });
    }
  };

  render() {
    return (
      <form onSubmit={this.handleUpdatePassword}>
        <FormGroup controlId="currentPassword">
          <FormLabel>Current password</FormLabel>
          <FormControl data-testid='currentPassword' autoFocus type="password" placeholder="Enter current password" value={this.state.currentPassword} onChange={this.handleChange} />
        </FormGroup>
        <FormGroup controlId="newPassword">
          <FormLabel>New password</FormLabel>
          <FormControl data-testid='newPassword' type="password" placeholder="Enter new password" value={this.state.newPassword} onChange={this.handleChange} />
        </FormGroup>
        <FormGroup controlId="confirmPassword">
          <FormLabel>Confirm new password</FormLabel>
          <FormControl data-testid='confirmPassword' type="password" placeholder="Confirm new password" value={this.state.confirmPassword} onChange={this.handleChange} />
        </FormGroup>
        {this.state.errors.length > 0 && <Popup divClassName="errorDialog" textClassName="errorText" alertText={this.state.errors.join(<br />)} />}
        <Button data-testid='changePassword' className="tableButton" disabled={!this.validateForm()} small>
          Change Password
        </Button>
      </form>
    );
  }
}
export default PasswordForm;
