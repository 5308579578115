/*
*/
import React from "react";
import { connect } from "react-redux";
import { addTimePeriod, addLocation, addTitle, searchData, addResults, addShowSuccess, addSuccessMsg, addErrors } from "../../../../../../actions/index";
import Modal from "react-bootstrap/Modal";
import { Auth } from "aws-amplify";
import Popup from "../../../../../components/Popup/Popup";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import Button from "@ausbom/button";
import DropDown from '../../../../../components/DropDown/DropDown'
import { createBrowserHistory } from "history";

// Handle props used for this component.
function mapDispatchToProps(dispatch) {
  return {
    addTimePeriod: timePeriod => dispatch(addTimePeriod(timePeriod)),
    addLocation: locations => dispatch(addLocation(locations)),
    addTitle: title => dispatch(addTitle(title)),
    addErrors: errors => dispatch(addErrors(errors)),
    searchData: searches => dispatch(searchData(searches)),
    addResults: results => dispatch(addResults(results)),
    addShowSuccess: showSuccess => dispatch(addShowSuccess(showSuccess)),
    addSuccessMsg: successMsg => dispatch(addSuccessMsg(successMsg))
  };
}

const mapStateToProps = state => {
  return {
    timePeriod: state.currentSearch.timePeriod,
    locations: state.currentSearch.locations,
    title: state.title,
    searches: state.searches,
    comparison: state.currentSearch.comparison,
    eCondition: state.currentSearch.eCondition,
    hourRange: state.currentSearch.hourRange,
    locationsValid: state.locationsValid,
    results: state.results,
    errors: state.errors,
    startMonth: state.currentSearch.startMonth,
    endMonth: state.currentSearch.endMonth,
    startYear: state.currentSearch.startYear,
    endYear: state.currentSearch.endYear,
    periodOption: state.currentSearch.periodOption,
    startDay: state.currentSearch.startDay,
    endDay: state.currentSearch.endDay,
    calculate: state.currentSearch.calculate,
    outputs: state.currentSearch.outputs
  };
};

class SaveSearch extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      isLoading: false,
      hideTitleAlert: true,
      hideSuccess: true,
      hideValidForm: true,
      errorMessage: "",
      invalidOutputs: false,
      userData: {},
      groups: [],
      selectedGroup: ''
    };

    SaveSearch.propTypes = {
      timePeriod: PropTypes.string,
      locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      title: PropTypes.string,
      searches: PropTypes.array,
      comparison: PropTypes.string,
      eCondition: PropTypes.string,
      hourRange: PropTypes.string,
      results: PropTypes.object,
      errors: PropTypes.array,
      locationsValid: PropTypes.bool,
      periodOption: PropTypes.string,
      startYear: PropTypes.number,
      startMonth: PropTypes.number,
      startDay: PropTypes.number,
      endYear: PropTypes.number,
      endMonth: PropTypes.number,
      endDay: PropTypes.number,
      outputs: PropTypes.object,
      calculate: PropTypes.string,
      addTimePeriod: PropTypes.func,
      addLocation: PropTypes.func,
      addTitle: PropTypes.func,
      searchData: PropTypes.func,
      addResults: PropTypes.func,
      addShowSuccess: PropTypes.func,
      addSuccessMsg: PropTypes.func,
      addErrors: PropTypes.func
    };
    this.saveSearchPopup = this.saveSearchPopup.bind(this)

    this.textInput = React.createRef();
    this.locationInput = React.createRef();
  }

  /**
   * @desc Handles the user typing in the save search title text box. 
   * @param {event} event used to set the prop of the title based on what the user entered to the text box.
   */
  handleChange = async event => {
    this.props.addTitle({ title: event.target.value });
  };

  /**
   * @desc Check if user is authed, if not, push user to login page.
   * Call searches API to get all users saved searches.
   */
  async componentDidMount() {
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (error) {
      const customHistory = createBrowserHistory();
      customHistory.replace('/#/login')
      customHistory.go()
    }
    try {
      const header = {
        headers: { Authorization: `Bearer ${jwtToken}` }
      };
      let data = await API.get("groups", "/searches", header);
      this.props.searchData({ searches: data.user });
    } catch (error) {
      // this.handlePopupAlerts("hideValidForm", false, "errorMessage", "There was an error loading your saved searches. Please try again or contact our support.");
      //this.timer();
    }
  }

  /**
   * @desc Check if an output is selected or not to determine if save search button needs to be disabled.
   * @param {*} prevProps used to compare against current props to detect change.
   */
  async componentDidUpdate(prevProps) {
    if (this.props.outputs !== prevProps.outputs) {
      for (const key in this.props.outputs) {
        if (this.props.outputs[key].checked === true) {
          await this.setState({ invalidOutputs: false })
          break;
        } else {
          await this.setState({ invalidOutputs: true })
        }
      }
    }
  }

  /**
   * @desc get users groups and open the save search modal. 
   */
  async saveSearchPopup() {
    const sessionInfo = await Auth.currentSession();
    await this.setState({ userData: sessionInfo.getIdToken().payload })
    await this.setState({ groups: this.state.userData['cognito:groups'] })
    this.handleModel("show", true);
  }

  /**
   * @desc check if user is authed, if not, send to login page.
   * Check what time period is selected as they will display differently in table and in the save.
   * Build the API call with all the form prop information and check if successful.
   * Possible errors to be displayed will be due to location not valid for user or function error.
   * If successful, an alert will display and the modal will close.
   */
  saveSearch = async () => {
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (error) {
      const customHistory = createBrowserHistory();
      customHistory.replace('/#/login')
      customHistory.go()
    }
    this.setState({ hideTitleAlert: true });
    this.setState({ isLoading: true });
    var time;
    if (this.props.periodOption === "default") {
      time = this.props.timePeriod;
    } else {
      time = `${this.props.startYear}/${this.props.startMonth}/${this.props.startDay} - ${this.props.endYear}/${this.props.endMonth}/${this.props.endDay}`;
    }
    const outputsArray = Object.keys(this.props.outputs).filter(output => this.props.outputs[output].checked === true);
    const header = {
      headers: { Authorization: `Bearer ${jwtToken}` },
      body: {
        title: `${this.props.title}`,
        locations: `${this.props.locations}`,
        periodType: `${time}`,
        start_day: `${this.props.startDay}`,
        start_month: `${this.props.startMonth}`,
        start_year: `${this.props.startYear}`,
        end_day: `${this.props.endDay}`,
        end_month: `${this.props.endMonth}`,
        end_year: `${this.props.endYear}`,
        comparison: `${this.props.comparison}`,
        e_conditions: `${this.props.eCondition}`,
        hourRange: `${this.props.hourRange}`,
        calculate: `${this.props.calculate}`,
        outputs: `${outputsArray}`,
        group: `${this.state.selectedGroup}`
      }
    };
    await API.post("groups", `/searches`, header)
      .then(() => {
        this.componentDidMount();
        this.props.addSuccessMsg({
          successMsg: "Search successfully saved."
        });
        this.props.addShowSuccess({ showSuccess: true });
      })
      .catch(error => {
        if (typeof error !== 'string') {
          this.props.addErrors({ errors: [error.response.data] })
        } else {
          this.props.addErrors({ errors: [error] })
        }
      });
    this.setState({ isLoading: false });
    this.handleModel("show", false);

  };

  // Accepts model name and true or false for closing it or opening it.
  /**
   * @desc used for opening and closing the modal.
   * @param {string} variable what modal is to be changed.
   * @param {boolean} bool true or false.
   */
  handleModel = (variable, bool) => {
    this.setState({ hideTitleAlert: true });
    this.setState({ [variable]: bool });
  };

  date_diff_indays(date1, date2) {
    console.log(date1)
    console.log(date2)
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);
    console.log(dt1)
    console.log(dt2)
    console.log(Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24)))
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }

  diff_months() {
    if (this.props.startMonth === 1 && this.props.endMonth !== 12) {
        return false;
    } else if (this.props.startMonth === 1 && this.props.endMonth === 12) {
        return true;
    } else if (this.props.endMonth !== (this.props.startMonth - 1)) {
        return false;
    } else {
        return true;
    }
}

  /**
   * @desc determines if the save search button to open the modal is disabled or not.
   * If no valid locations have been entered or no outputs selected, it will return true.
   * @return true or false.
   */
  isDisabled() {
    if (this.props.calculate === 'daily' && this.date_diff_indays(`${this.props.startMonth}/${this.props.startDay}/${this.props.startYear}`, `${this.props.endMonth}/${this.props.endDay}/${this.props.endYear}`) > 62) {
      console.log('false')
      return true
    } else if (this.props.calculate === 'yearly' && this.diff_months() === false) {
      return true
    } else if (this.props.locationsValid === true && this.state.invalidOutputs === false) {
      return false
    } else {
      return true
    }
  }

  /**
   * @desc used for getting the users group selection.
   * @param {event} event used to determine what group was selelected.
   */
  handleDropdownSelect = event => {
    const { value } = event.target;
    this.setState({ selectedGroup: value })
  };

  /**
   * @desc Determines if the save search button is disabled or not.
   * If both a name isn't given and a group not selected, it will return true.
   * @return true or false.
   */
  isDisabledSave() {
    if (this.props.title === '' || this.state.selectedGroup === '') {
      return true
    } else {
      return false
    }
  }

  render() {
    return (
      <>
        <Button className='primaryButton' data-testid='saveSerchButton' onClick={this.saveSearchPopup} disabled={this.isDisabled()} small>
          Save search
        </Button>

        <Modal data-testid='saveSearchModal' size="lg" show={this.state.show} onHide={() => this.handleModel("show", false)}>
          <Modal.Header closeButton>
            <Modal.Title>Current search save details: </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-hover" id="data-requirements">
              <thead className="thead-light">
                <tr>
                  <th className="tableHead">Variable</th>
                  <th className="tableHead">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Locations: </td>
                  <td className="locationsPara">
                    {Object.keys(this.props.locations).map((dg, i) => (
                      <span key={i} value={this.props.locations[dg]}>
                        {this.props.locations[dg]},
                      </span>
                    ))}
                  </td>
                </tr>
                <tr>
                  {this.props.periodOption === "default" && (
                    <>
                      <td>Time period: </td>
                      <td>{`${this.props.timePeriod}`}</td>
                    </>
                  )}
                  {this.props.periodOption === "custom" && (
                    <>
                      <td>Time period: </td>
                      <td>{`${this.props.startDay}/${this.props.startMonth}/${this.props.startYear} - ${this.props.endDay}/${this.props.endMonth}/${this.props.endYear}`}</td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>Calculated: </td>
                  <td>{this.props.calculate}</td>
                </tr>
                <tr>
                  <td>Comparison: </td>
                  <td>{this.props.comparison}</td>
                </tr>
                <tr>
                  <td>Enviromental condition: </td>
                  <td>{this.props.eCondition === "weather" ?  "thunderstorms" : this.props.eCondition}</td>
                </tr>
                <tr>
                  <td>Time series: </td>
                  <td>{this.props.hourRange}</td>
                </tr>
                <tr>
                  <td>Outputs:</td>
                  <td>
                    {Object.keys(this.props.outputs)
                      .filter(output => this.props.outputs[output].checked === true)
                      .map(output => this.props.outputs[output].title)
                      .join(", ")}
                  </td>
                </tr>
                <tr>
                  <td>Enter name: </td>
                  <td>
                    <input autoComplete="off" data-testid='saveSearchName' className="formInput inputWidthMedium" type="text" name="locations" id="title-text" value={this.props.title} onChange={this.handleChange} />
                  </td>
                </tr>
                <tr>
                  <td>Select group to save search to:</td>
                  <td>
                    <DropDown
                      defaultItem={true}
                      dropDownType='userGroups'
                      selectGroupId='userGroups'
                      selectRef={this.customGroupInput}
                      optionItems={this.state.groups}
                      handleDropSelect={this.handleDropdownSelect}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {this.state.hideTitleAlert === false && <Popup divClassName="errorDialog" textClassName="errorText" alertText="Search must be given a name." />}

          </Modal.Body>
          <Modal.Footer>
            <Button data-testid='saveSearchModalButton' onClick={this.saveSearch} disabled={this.isDisabledSave()} small>
              Save search
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const SaveSearchExport = connect(mapStateToProps, mapDispatchToProps)(SaveSearch);

export default SaveSearchExport;
