import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Popup from "../components/Popup/Popup";
import { FormGroup, FormControl } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import Button from "@ausbom/button";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      username: "",
      password: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false,
      errors: [],
      isOpen: false
    };
  }

  /**
   * @desc Validate to make sure a username is entered.
   */
  validateCodeForm() {
    return this.state.username.length > 0;
  }

  /**
   * @desc Validate to make sure code and noth password fields are not blank.
   */
  validateResetForm() {
    return this.state.code.length > 0 && this.state.password.length > 0 && this.state.password === this.state.confirmPassword;
  }

  /**
   * @desc Handles all form inputs from the user.
   * @param {event} event Is used to determine what state to update and the value to use.
   */
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  /**
   * @desc Get auth code by sending username to auth with forgotPassword.
   * Show error if issue with entered username.
   * @param {event} event 
   */
  handleSendCodeClick = async event => {
    event.preventDefault();
    this.setState({ isSendingCode: true });
    try {
      await Auth.forgotPassword(this.state.username);
      this.setState({ codeSent: true });
      this.setState({ errors: [] });
    } catch (e) {
      this.setState({ errors: [e.message] });
      this.setState({ isSendingCode: false });
    }
  };

  /**
   * @desc Handle the confirmation of click after entering code.
   * Show error if the code was incorrect.
   * @param {event} event 
   */
  handleConfirmClick = async event => {
    event.preventDefault();
    this.setState({ isConfirming: true });
    try {
      await Auth.forgotPasswordSubmit(this.state.username, this.state.code, this.state.password);
      this.setState({ confirmed: true });
      this.setState({ errors: [] });
    } catch (e) {
      this.setState({ errors: [e.message] });
      this.setState({ isConfirming: false });
    }
  };

  renderRequestCodeForm() {
    return (
      <>
        <h4>Reset your password</h4>
        <form onSubmit={this.handleSendCodeClick}>
          <FormGroup bssize="large" controlId="username">
            <label>Username</label>
            <FormControl data-testid='username' autoFocus type="username" value={this.state.username} onChange={this.handleChange} />
          </FormGroup>
          {this.state.errors.length > 0 && <Popup divClassName="errorDialog" textClassName="errorText" alertText={this.state.errors.join(<br />)} />}
          <Button data-testid='sendCode' disabled={!this.validateCodeForm()} small>
            Send confirmation
          </Button>
        </form>
      </>
    );
  }

  renderConfirmationForm() {
    return (
      <>
        <h4>Reset your password</h4>
        <form onSubmit={this.handleConfirmClick}>
          <FormGroup bssize="large" controlId="code">
            Confirmation code
            <FormControl data-testid='code' autoFocus type="tel" value={this.state.code} onChange={this.handleChange} />
            <small>Please check your phone for the confirmation code.</small>
          </FormGroup>
          <hr />
          <FormGroup bssize="large" controlId="password">
            New password
            <FormControl data-testid='password' type="password" value={this.state.password} onChange={this.handleChange} />
          </FormGroup>
          <FormGroup bssize="large" controlId="confirmPassword">
            Confirm password
            <FormControl data-testid='confirmPassword' type="password" onChange={this.handleChange} value={this.state.confirmPassword} />
          </FormGroup>
          {this.state.errors.length > 0 && <Popup divClassName="errorDialog" textClassName="errorText" alertText={this.state.errors.join(<br />)} />}
          <Button data-testid='confirm' disabled={!this.validateResetForm()} small>
            Confirm
          </Button>
        </form>
      </>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <FaCheck />
        <p>Your password has been reset.</p>
        <p>
          <Link to="/login">Click here to login with your new credentials.</Link>
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="container">
        <div className="login-box">
          <div className=" container">{!this.state.codeSent ? this.renderRequestCodeForm() : !this.state.confirmed ? this.renderConfirmationForm() : this.renderSuccessMessage()}</div>
        </div>
      </div>
    );
  }
}
