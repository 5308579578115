/*
This component handles the error and success popup messages throughout the application.
*/
import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal'

class PopupModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        PopupModal.propTypes = {
            title: PropTypes.string,
            body: PropTypes.object,
            show: PropTypes.bool,
            selectRef: PropTypes.string,
            optionItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
            defaultItem: PropTypes.bool,
            dropDownType: PropTypes.string
        }

        this.state = {
            show: this.props.show
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.show !== prevProps.show) {
            this.setState({ show: this.props.show })
        }
    }

    handleModel = () => {
        this.setState({ show: false })
    }

    render = () =>
        <Modal size="lg" show={this.state.show} onHide={e => { this.handleModel(e); this.props.onHide && this.props.onHide(e); }}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.props.body}
            </Modal.Body>
            <Modal.Footer>
                {this.props.footer}
            </Modal.Footer>
        </Modal>
}

const PopupModals = connect()(PopupModal);

export default PopupModals