/*
*/
import React from 'react'
import { connect } from "react-redux";
import { addOutput } from "../../../../../../actions/index";
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types';

// Handle props used for this component.
function mapDispatchToProps(dispatch) {
    return {
        addOutput: output => dispatch(addOutput(output))
    };
}

const mapStateToProps = state => {
    return {
        outputs: state.currentSearch.outputs,
        calculate: state.currentSearch.calculate
    };
};

class Outputs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...Object.keys(this.props.outputs).map(output => { return {[output + 'Class']: 'active'} })
        }

        Outputs.propTypes = {
            outputs: PropTypes.object,
            calculate: PropTypes.string,
            addOutput: PropTypes.func
        }

        this.toggle = this.toggle.bind(this)
    }

    /**
     * @desc handles the toggle event for all of the Output options by changing the prop value.
     * @param {event} event determines what option was selected.
     */
    async toggle(event){
        this.props.addOutput({ key: event.target.id, value: !this.props.outputs[event.target.id].checked })
    }

    render() {
        return (
            <div className="searchBoxes search-components searchBoxBottom">
                    <div>
                            <h4 className="titleText">Output options</h4>
                                <div className="row ">
                                    <Form>
                                        <div className='col'>
                                            {Object.keys(this.props.outputs).map(output => 
                                                <Form.Check 
                                                    key={output}
                                                    className={this.state[output + 'Class']}
                                                    custom
                                                    label={this.props.outputs[output].title}
                                                    type="checkbox"
                                                    id={output}
                                                    checked={this.props.outputs[output].checked}
                                                    onChange={this.toggle}
                                                />
                                            )}
                                        </div>
                                    </Form>
                                </div>
                    </div>
            </div>
        )
    }
}
const OutputsExport = connect(mapStateToProps, mapDispatchToProps)(Outputs);

export default OutputsExport
