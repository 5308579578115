import React from "react";
import { connect } from "react-redux";
import { addLocationsValid, addGroups, addGroupId, addErrors, addShowSuccess, addSuccessMsg } from "../../../../../../actions/index";
import { Auth } from "aws-amplify";
import Popup from "../../../../../components/Popup/Popup";
import PropTypes from "prop-types";
import PopupModal from "../../../../../components/PopupModal/PopupModal";
import { API } from "aws-amplify";
import Button from "@ausbom/button";
import DropDown from '../../../../../components/DropDown/DropDown'
import { createBrowserHistory } from "history";

// Handle props used for this component.
function mapDispatchToProps(dispatch) {
  return {
    addLocationsValid: locationsValid => dispatch(addLocationsValid(locationsValid)),
    addGroups: groups => dispatch(addGroups(groups)),
    addGroupId: groupId => dispatch(addGroupId(groupId)),
    addErrors: errors => dispatch(addErrors(errors)),
    addShowSuccess: showSuccess => dispatch(addShowSuccess(showSuccess)),
    addSuccessMsg: successMsg => dispatch(addSuccessMsg(successMsg))
  };
}

const mapStateToProps = state => {
  return {
    locations: state.currentSearch.locations,
    locationsValid: state.locationsValid,
    groups: state.groups,
    errors: state.errors,
    successMsg: state.successMsg
  };
};

class SaveGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveModal: false,
      groupNameError: false,
      errors: [],
      successMsg: '',
      userData: {},
      groups: [],
      selectedGroup: '',
      title: ''
    };

    SaveGroup.propTypes = {
      locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      locationsValid: PropTypes.bool,
      addLocationsValid: PropTypes.func,
      addGroups: PropTypes.func,
      addGroupId: PropTypes.func,
      addErrors: PropTypes.func
    };
    this._saveGroupPopup = this._saveGroupPopup.bind(this)
    this.saveGroup = this.saveGroup.bind(this)
    this.locationInput = React.createRef();
    this.groupNameInput = React.createRef();
    this.isDisabled = this.isDisabled.bind(this)
  }

  /**
   * @desc Retrieved the users groups to display in a dropdown and opens the modal.
   */
  async _saveGroupPopup() {
    if (this.props.locationsValid === true) {
      const sessionInfo = await Auth.currentSession();
      this.setState({ userData: sessionInfo.getIdToken().payload })
      this.setState({ groups: this.state.userData['cognito:groups'] })
      this.setState({ saveModal: true });
    }
  };

  /**
   * @desc calls the save group api and adds the new group for the user.
   * @try gets the users jwtToken for the api call
   * @catch if user is no longer authed, send them to login page.
   * @desc calls the save group api, if successful, display a success alert, if not, display error alert.
   */
  async saveGroup() {
    console.log('----------------')
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (error) {
      const customHistory = createBrowserHistory();
      customHistory.replace('/#/login')
      customHistory.go()
    }

    const header = {
      headers: { Authorization: `Bearer ${jwtToken}` },
      body: {
        title: `${this.state.title}`,
        locations: `${this.props.locations}`,
        task: `save`,
        group: `${this.state.selectedGroup}`
      }
    };
    this.setState({ groupNameError: false });
    this.setState({ isLoading: true });
    await API.post("groups", `/groups`, header)
      .then(response => {
        this.props.onChange()
        this.props.addSuccessMsg({ successMsg: "Group successfully saved." });
        this.props.addShowSuccess({ showSuccess: true });
      })
      .catch(error => {
        if (typeof error !== 'string') {
            this.props.addErrors({ errors: [error.response.data] })
        } else {
            this.props.addErrors({ errors: [error] })
        }
      });
    this.setState({ isLoading: false });
    this.setState({ saveModal: false });
  };

  /**
   * @desc handles the change for the save group name.
   * @param {event} event is used to ge the input value for the state.
   */
  handleChange = async event => {
    console.log('----------------')

    this.setState({ title: event.target.value })
  };

  /**
   * @desc Handles setting the state of the selected group when user uses the group dropdown.
   * @param {*} event used to get the group name for the group they want to save the location group to.
   */
  handleDropdownSelect = async event => {
    console.log('----------------')
    console.log(event.target.value)

    const { value } = event.target;
    await this.setState({ selectedGroup: value })
    console.log(this.state.selectedGroup)
  };

  /**
   * @desc checks if form is valid.
   * @return true or false to determine if save group button is disabled or not.
   */
  isDisabled() {
    if (this.state.title === '' || this.state.selectedGroup === '') {
      return true
    } else {
      return false
    }
  }

  render() {
    return (
      <>
        <Button data-testid="saveGroupPopup" onClick={this._saveGroupPopup} disabled={!this.props.locationsValid} small>
          Save group
        </Button>
        <PopupModal
          show={this.state.saveModal}
          title="Current search group details: "
          body={
            <>
              <p className="locationsPara">{this.props.locations.join(",")}</p>
              <input autoComplete="off" value={this.state.title} onChange={this.handleChange} ref={this.groupNameInput} className="formInput inputWidthWide" placeholder="Enter location group name" type="text" name="locations" data-testid="title-text" id="title-text" />
              {this.state.groupNameError && <Popup divClassName="errorDialog" textClassName="errorText" alertText="Location group must be given a name." />}
              <label className="dropDownLabel dropDownLabelTop">Select what user group to save this to:</label>
              <DropDown
                defaultItem={true}
                dropDownType='userGroups'
                selectGroupId='userGroups'
                selectRef={this.customGroupInput}
                optionItems={this.state.groups}
                handleDropSelect={this.handleDropdownSelect}
              />
            </>
          }
          onHide={() => this.setState({ saveModal: false })}
          footer={
            <Button data-testid="saveGroup" onClick={this.saveGroup} disabled={this.isDisabled()} small>
              Save group
            </Button>
          }
        />
      </>
    );
  }
}
const SaveGroupExport = connect(mapStateToProps, mapDispatchToProps)(SaveGroup);

export default SaveGroupExport;
