import { ADD_LOCATIONS } from "../constants/action-types";
import { ADD_TIME_PERIOD } from "../constants/action-types";
import { ADD_TITLE } from "../constants/action-types";
import { ADD_SEARCHES } from "../constants/action-types";
import { ADD_ALL_SEARCHES } from "../constants/action-types";
import { ADD_GROUPS } from '../constants/action-types';
import { ADD_ALL_GROUPS } from '../constants/action-types';
import { ADD_COMPARISON } from "../constants/action-types";
import { ADD_E_CONDITION } from "../constants/action-types";
import { ADD_HOUR_RANGE } from "../constants/action-types";
import { ADD_LOCATIONS_VALID } from '../constants/action-types';
import { ADD_RESULTS } from '../constants/action-types';
import { ANNOUNCE_502_ERROR } from '../constants/action-types';
import { ADD_ERRORS } from '../constants/action-types';
import { ADD_START_MONTH } from '../constants/action-types';
import { ADD_START_YEAR } from '../constants/action-types';
import { ADD_END_MONTH } from '../constants/action-types';
import { ADD_END_YEAR } from '../constants/action-types';
import { ADD_PERIOD_OPTION } from '../constants/action-types';
import { ADD_START_DAY } from '../constants/action-types';
import { ADD_END_DAY } from '../constants/action-types';
import { ADD_CALCULATE } from '../constants/action-types';
import { ADD_TOGGLE } from '../constants/action-types';
import { ADD_OUTPUT } from '../constants/action-types';
import { ADD_GROUP_ID } from '../constants/action-types';
import { ADD_SEARCH_LOADING } from '../constants/action-types';
import { ADD_LAST_SEARCH } from '../constants/action-types';
import { ADD_SHOW_SUCCESS } from '../constants/action-types';
import { ADD_SUCCESS_MSG } from '../constants/action-types';
import { ADD_EMAILS } from '../constants/action-types';
import { ADD_EDIT_SEARCH } from '../constants/action-types';
import { ADD_EDIT_POPUP } from '../constants/action-types';


export function addLocation(payload) {
  return { type: ADD_LOCATIONS, payload };
}

export function addTimePeriod(payload) {
  return { type: ADD_TIME_PERIOD, payload };
}

export function addTitle(payload) {
  return { type: ADD_TITLE, payload };
}

export function searchData(payload) {
  return { type: ADD_SEARCHES, payload };
}

export function allSearchData(payload) {
  return { type: ADD_ALL_SEARCHES, payload };
}

export function addGroups(payload) {
  return { type: ADD_GROUPS, payload };
}

export function addAllGroups(payload) {
  return { type: ADD_ALL_GROUPS, payload };
}

export function addComparison(payload) {
  return { type: ADD_COMPARISON, payload };
}

export function addECondition(payload) {
  return { type: ADD_E_CONDITION, payload };
}

export function addHourRange(payload) {
  return { type: ADD_HOUR_RANGE, payload };
}

export function addLocationsValid(payload) {
  return { type: ADD_LOCATIONS_VALID, payload };
}

export function addResults(payload) {
  return { type: ADD_RESULTS, payload };
}

export function announce502Error(payload) {
  return { type: ANNOUNCE_502_ERROR, payload };
}

export function addErrors(payload) {
  return { type: ADD_ERRORS, payload };
}

export function addStartMonth(payload) {
  return { type: ADD_START_MONTH, payload };
}

export function addStartYear(payload) {
  return { type: ADD_START_YEAR, payload };
}

export function addEndMonth(payload) {
  return { type: ADD_END_MONTH, payload };
}

export function addEndYear(payload) {
  return { type: ADD_END_YEAR, payload };
}

export function addPeriodOption(payload) {
  return { type: ADD_PERIOD_OPTION, payload };
}

export function addStartDay(payload) {
  return { type: ADD_START_DAY, payload };
}

export function addEndDay(payload) {
  return { type: ADD_END_DAY, payload };
}

export function addCalculate(payload) {
  return { type: ADD_CALCULATE, payload };
}

export function addToggle(payload) {
  return { type: ADD_TOGGLE, payload };
}

export function addOutput(payload) {
  return { type: ADD_OUTPUT, payload };
}

export function addGroupId(payload) {
  return { type: ADD_GROUP_ID, payload };
}

export function addSearchLoading(payload) {
    return {type: ADD_SEARCH_LOADING, payload }
}

export function addLastSearch(payload) {
    return {type: ADD_LAST_SEARCH, payload}
}

export function addShowSuccess(payload) {
  return {type: ADD_SHOW_SUCCESS, payload}
}

export function addSuccessMsg(payload) {
  return {type: ADD_SUCCESS_MSG, payload}
}

export function addEmails(payload) {
  return { type: ADD_EMAILS, payload };
}

export function addEditSearch(payload) {
  return { type: ADD_EDIT_SEARCH, payload };
}

export function addEditPopup(payload) {
  return { type: ADD_EDIT_POPUP, payload };
}