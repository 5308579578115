/*
This component is the main container of the app. It holds holds the locations, time and other params components as well as results.
*/
import React from 'react';
import { connect } from "react-redux";
import Button from '@ausbom/button'
import { createBrowserHistory } from "history";
import { addResults, addErrors, addSearchLoading, addLastSearch, announce502Error } from "../../../../../../actions/index";
import { Auth } from "aws-amplify";
import PropTypes from 'prop-types';
import { API } from "aws-amplify";

// Handle props used for this component.
function mapDispatchToProps(dispatch) {
    return {
        addResults: results => dispatch(addResults(results)),
        addErrors: errors => dispatch(addErrors(errors)),
        addSearchLoading: searchLoading => dispatch(addSearchLoading(searchLoading)),
        addLastSearch: lastSearch => dispatch(addLastSearch(lastSearch)),
        announce502Error: error => dispatch(announce502Error(error)),
    };
}

const mapStateToProps = state => {
    return {
        searchLoading: state.searchLoading,
        locations: state.currentSearch.locations,
        comparison: state.currentSearch.comparison,
        timePeriod: state.currentSearch.timePeriod,
        eCondition: state.currentSearch.eCondition,
        hourRange: state.currentSearch.hourRange,
        locationsValid: state.locationsValid,
        results: state.results,
        errors: state.errors,
        startMonth: state.currentSearch.startMonth,
        endMonth: state.currentSearch.endMonth,
        startYear: state.currentSearch.startYear,
        endYear: state.currentSearch.endYear,
        periodOption: state.currentSearch.periodOption,
        startDay: state.currentSearch.startDay,
        endDay: state.currentSearch.endDay,
        calculate: state.currentSearch.calculate,
        outputs: state.currentSearch.outputs
    };
};

class SearchButton extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            hideTitleAlert: true,
            hideSuccess: true,
            hideValidForm: true,
            errorMessage: '',
            invalidOutputs: false
        };

        SearchButton.propTypes = {
            locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
            comparison: PropTypes.string,
            timePeriod: PropTypes.string,
            eCondition: PropTypes.string,
            hourRange: PropTypes.string,
            results: PropTypes.object,
            errors: PropTypes.array,
            locationsValid: PropTypes.bool,
            startYear: PropTypes.number,
            startMonth: PropTypes.number,
            startDay: PropTypes.number,
            endYear: PropTypes.number,
            endMonth: PropTypes.number,
            endDay: PropTypes.number,
            periodOption: PropTypes.string,
            searchLoading: PropTypes.bool,
            calculate: PropTypes.string,
            addSearchLoading: PropTypes.func,
            searchData: PropTypes.func,
            addErrors: PropTypes.func,
            announce502Error: PropTypes.func,
            addResults: PropTypes.func,
            addLastSearch: PropTypes.func,
            outputs: PropTypes.object
        }

        this.textInput = React.createRef();
        this.locationInput = React.createRef();

    }

    /**
     * @desc Determines if at least one output has been selected, otherwise display an error and disable search.
     * @param {*} prevProps used to compare against current props to detect change.
     */
    async componentDidUpdate(prevProps) {
        if (this.props.outputs !== prevProps.outputs) {
            for (const key in this.props.outputs) {
                if (this.props.outputs[key].checked === true) {
                    await this.setState({ invalidOutputs: false })
                    this.props.addErrors({ errors: [] })
                    break;
                } else {
                    await this.setState({ invalidOutputs: true })
                    this.props.addErrors({ errors: ['At least one output must be selected.'] })
                }
            }
        }
    }

    /**
     * @desc checks if user is authed, if not push to login page.
     * Builds api search call using all the form prop data.
     * Possible errors include function error, location not allowed for user and missing data.
     * If successful, data will be displayed in bottom area of page with the selected outputs shown.
     */
    search = async () => {
        this.props.announce502Error({error :null});
        if (this.props.locationsValid === true) {
            let sessionInfo
            let jwtToken
            try {
                sessionInfo = await Auth.currentSession();
                jwtToken = sessionInfo.getIdToken().getJwtToken()
            } catch (error) {
                console.log(error)
                const customHistory = createBrowserHistory();
                customHistory.replace('/#/login')
                customHistory.go()
            }
            this.props.addSearchLoading({ searchLoading: true });
            this.props.addLastSearch({
                lastSearch: {
                    locations: this.props.locations,
                    comparison: this.props.comparison,
                    timePeriod: this.props.timePeriod,
                    eCondition: this.props.eCondition,
                    hourRange: this.props.hourRange,
                    startMonth: this.props.startMonth,
                    endMonth: this.props.endMonth,
                    startYear: this.props.startYear,
                    endYear: this.props.endYear,
                    periodOption: this.props.periodOption,
                    startDay: this.props.startDay,
                    endDay: this.props.endDay,
                    calculate: this.props.calculate,
                    outputs: this.props.outputs
                }
            })

            try {
                const outputsArray = Object.keys(this.props.outputs).filter(output => this.props.outputs[output].checked === true)
                const header = {
                    headers: { Authorization: `Bearer ${jwtToken}` }
                }
              
                // Set the maximum number of retry attempts
                const maxRetries = 3;
                let retries = 0;
              
                while (retries < maxRetries) {
                  try {
                    let data = await API.get("groups", `/search/${this.props.locations}/${this.props.startYear}/${this.props.startMonth}/${this.props.startDay}/${this.props.endYear}/${this.props.endMonth}/${this.props.endDay}/${this.props.comparison}/${this.props.eCondition}/${this.props.hourRange}/${this.props.calculate}/${outputsArray}`, header);
                    if (data.test === 202) {
                      this.props.addResults({ results: {} });
                      this.props.addErrors({ errors: [data.response] });
                    } else {
                      this.props.addResults({ results: data.data });
                      this.props.addErrors({ errors: data.errors });
                    }
              
                    // Break out of the loop if the request was successful
                    break;
                  } catch (error) {
                    console.log(error);
              
                    // Check if the response status code is 502 (Bad Gateway)
                    if (error.response && error.response.status === 502) {
                      // Increment the retry counter
                      retries++;

                      this.props.announce502Error({error :{
                        retries
                      }})

                      // Handle the case when all retries have failed
                      if (retries > maxRetries) {
                        throw error;
                      } 
              
                      // Wait for a delay before retrying
                      let delayBetweenRetries;
                      if (retries === 1) {
                        delayBetweenRetries = 100;
                      } else if (retries === 2) {
                        delayBetweenRetries = 250;
                      } else {
                        delayBetweenRetries = 500;
                      }

                      await new Promise((resolve) =>
                        setTimeout(resolve, delayBetweenRetries)
                      );
                    } else {
                      // If it's not a 502 error, don't retry and exit the loop
                      this.props.addErrors({ errors: [error.response.data] });
                      this.props.addResults({ results: {} });
                      break;
                    }
                  }
                }
            } catch (error) {
                console.error("Unhandled error:", error);
                this.props.addErrors({ errors: [error.message] });
                this.props.addResults({ results: {} });
            }
            this.props.addSearchLoading({ searchLoading: false })
        }
    }

    date_diff_indays(date1, date2) {
        let dt1 = new Date(date1);
        let dt2 = new Date(date2);
        console.log(Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24)))
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    }

    diff_months() {
        if (this.props.startMonth === 1 && this.props.endMonth !== 12) {
            return false;
        } else if (this.props.startMonth === 1 && this.props.endMonth === 12) {
            return true;
        } else if (this.props.endMonth !== (this.props.startMonth - 1)) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * @desc determines if valid input and output has been selected, if not, disable search button.
     */
    isDisabled() {
        if (this.props.calculate === 'daily' && this.date_diff_indays(`${this.props.startMonth}/${this.props.startDay}/${this.props.startYear}`, `${this.props.endMonth}/${this.props.endDay}/${this.props.endYear}`) > 62) {
            return true
        } else if (this.props.calculate === 'yearly' && this.diff_months() === false) {
            return true
        } else if (this.props.locationsValid === true && this.state.invalidOutputs === false) {
            return false
        } else {
            return true
        }
    }

    render() {
        return (
            <>
                <Button className='primaryButton' data-testid="buttonTest" onClick={this.search} disabled={this.isDisabled()} small>
                    Search
                </Button>
            </>
        )
    }
}

const SearchButtonExport = connect(mapStateToProps, mapDispatchToProps)(SearchButton);

export default SearchButtonExport