/*
This component is the main container of the app. It holds holds the locations, time and other params components as well as results.
*/
import React from "react";
import SearchResults from "../../results/search-results-component/search-results-component";
import { connect } from "react-redux";
import { searchData, allSearchData } from "../../../actions/index";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import SearchForm from "./tabs/search-form/search-form-component";
import SavedSearches from "./tabs/saved-searches/saved-searches-component";
import LoadingAnimation from "../loading-animation/loading-animation-component";
import SavedLocations from "./tabs/saved-locations/saved-locations-component";
import EmailsList from "./tabs/emails/emails-component";
import { Tab as NotTab, TabList, TabPanel } from "@ausbom/tabs";
import { API } from "aws-amplify";


// Handle props used for this component.
function mapDispatchToProps(dispatch) {
  return {
    searchData: searches => dispatch(searchData(searches)),
    allSearchData: allSearches => dispatch(allSearchData(allSearches))
  };
}

const mapStateToProps = state => {
  return {
    searches: state.searches,
    searchLoading: state.searchLoading
  };
};

class Search extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hideTitleAlert: true,
      hideSuccess: true,
      hideValidForm: true,
      errorMessage: "",
      tab: "currentSearch",
      selectedTab: "search"
    };

    Search.propTypes = {
      searches: PropTypes.array,
      searchData: PropTypes.func,
      allSearchData: PropTypes.func,
      searchLoading: PropTypes.bool
    };

    this.render = this.render.bind(this);
    this.timer = this.timer.bind(this);
  }

  /**
  * @desc On load, get users saved searches and groups saved searches.
  * Use Auth to get current user session info and pass jwtToken through API call.
  * @try call the get groups api and assign the returned data to the relevant props.
  * @catch If error with api call, display error and user timer function to hide it after 5 seconds.
  * @result Either groups will be returned and assigned to props or error displayed.
  */
  async componentDidMount() {
    const sessionInfo = await Auth.currentSession();
    const jwtToken = sessionInfo.idToken.jwtToken;
    const header = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };
    try {
      let data = await API.get("groups", "/searches", header);
      this.props.searchData({ searches: data.user });
      this.props.allSearchData({ allSearches: data.group });
    } catch (error) {
      this.handlePopupAlerts("hideValidForm", false, "errorMessage", "There was an error loading your saved searches. Please try again or contact our support.");
      this.timer();
    }
  }

  /**
  * @desc 5 second timer then hide success and valid form popups.
  */
  timer() {
    setTimeout(
      function() {
        this.setState({ hideSuccess: true });
        this.setState({ hideValidForm: true });
      }.bind(this),
      10000
    );
  }

  /**
  * @desc builds the popup alert to display for success or errors.
  * @param {string} variable the state variable to assign true or false.
  * @param {boolean} bool is assigned to the variable.
  * @param {string} textVariable The type of alert to be displayed.
  * @param {string} textMessage The text to be displayed in the alert.
  */
  handlePopupAlerts(variable, bool, textVariable, textMessage) {
    this.setState({ [textVariable]: textMessage });
    this.setState({ [variable]: bool });
  }

  /**
  * @desc Used for setting what tab has been selected.
  * @param {string} keys is the key for the tab being selected.
  */
  setKey = keys => {
    this.setState({ tab: keys });
    this.setState({ selectedTab: 'search' });
  };

  render() {
    return (
      <>
        <div className="container main-container" style={{ paddingBottom: 10 }}>
          <TabList>
            <NotTab className="tabTest" label="Search" active={this.state.selectedTab === "search"} tabId="search-tab" onClick={() => this.setState({ selectedTab: "search" })} />
            <NotTab label="Saved searches" active={this.state.selectedTab === "savedSearches"} tabId="savedSearches-tab" onClick={() => this.setState({ selectedTab: "savedSearches" })} />
            <NotTab label="Location groups" active={this.state.selectedTab === "locationGroups"} tabId="locationGroups-2" onClick={() => this.setState({ selectedTab: "locationGroups" })} />
            <NotTab label="Emails" active={this.state.selectedTab === "emails"} tabId="emails-tab" onClick={() => this.setState({ selectedTab: "emails" })} />
          </TabList>
          <TabPanel active={this.state.selectedTab === "search"} tabId="search-tab">
            <SearchForm setKey={this.setKey} />
          </TabPanel>
          <TabPanel active={this.state.selectedTab === "savedSearches"} tabId="savedSearches-tab">
            <SavedSearches setKey={this.setKey} />
          </TabPanel>
          <TabPanel active={this.state.selectedTab === "locationGroups"} tabId="locationGroups-tab">
            <SavedLocations setKey={this.setKey} />
          </TabPanel>
          <TabPanel active={this.state.selectedTab === "emails"} tabId="emails-tab">
            <EmailsList setKey={this.setKey} />
          </TabPanel>

        </div> 
        <div className="container">
      <hr/></div>
        {this.props.searchLoading ? <LoadingAnimation /> : <SearchResults />}
      </>
    );
  }
}

const SearchExport = connect(mapStateToProps, mapDispatchToProps)(Search);

export default SearchExport;
