import React from "react";
import { connect } from "react-redux";
import { addErrors } from "../../../../../actions/index";
import Times from "./time-period-container/time-component";
import OtherParams from "./other-params-container/other-params-component";
import Locations from "./locations-container/locations-component";
import Outputs from "./output-options-container/output-component";
import Popup from "../../../../components/Popup/Popup";
import SearchButton from "./main-search/search";
import SaveSearch from "./save-search/save-search";
import EmailFormPopup from "./email-form/email-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import ChevronDown16 from "@ausbom/icon/lib/icons/system/ChevronDown16";
import ChevronUp16 from "@ausbom/icon/lib/icons/system/ChevronUp16";
import _ from "lodash";
import PropTypes from "prop-types";
import Error24 from '@ausbom/icon/lib/icons/system/Error24'
import ErrorBoundary from "../../../../components/ErrorBoundry";

function mapDispatchToProps(dispatch) {
  return {
    addErrors: errors => dispatch(addErrors(errors))
  };
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    currentSearch: state.currentSearch,
    lastSearch: state.lastSearch,
    showSuccess: state.showSuccess,
    successMsg: state.successMsg,
    calculate: state.currentSearch.calculate,
    announce502Error: state.announce502Error
  };
};

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      searchChanged: false
    };

    SearchForm.propTypes = {
      hideValidForm: PropTypes.bool,
      errorMessage: PropTypes.string,
      hideSuccess: PropTypes.bool,
      successMessage: PropTypes.string,
      currentSearch: PropTypes.object,
      lastSearch: PropTypes.object,
      errors: PropTypes.array,
      addErrors: PropTypes.func,
      showSuccess: PropTypes.bool,
      successMsg: PropTypes.string,
      calculate: PropTypes.string,
      announce502Error:PropTypes.object,
    };
  }

  /**
   * @desc Checking if the form state has changed, if so display an alert.
   * @param {*} prevProps holds the previous state of props before change.
   * @param {*} prevState holds the previous state before change.
   */
  componentDidUpdate(prevProps, prevState) {
    const searchChanged = !_.isEqual(this.props.currentSearch, this.props.lastSearch) && Object.keys(this.props.lastSearch).length !== 0;
    if (prevState.searchChanged === searchChanged) return;

    if (searchChanged) {
      this.setState({ searchChanged: true });
      this.props.addErrors({ errors: [] });
    } else {
      this.setState({ searchChanged: false });
    }
  }

  /**
  * @desc A toggle for the accordion displaying the current errors.
  * called when the user clicks on the accordion.
  */
  toggleChevron = () => this.setState({ isOpen: !this.state.isOpen });

  /**
   * @desc Displays an alert information popup to tell the user the search has changed.
   * called when componentDidUpdate discovers a change to the prevState of the search prop.
   */
  sameSearchPopup = () => <Popup divClassName="infoDiv" textClassName="infoText" alertText={<>The search options have changed, please search again to update the search results.</>} />;

  dailyPopup = () => <Popup divClassName="infoDiv" textClassName="infoText" alertText={<>Daily searches can only be a maximum of 62 days.</>} />;

  yearlyPopup = () => <Popup divClassName="infoDiv" textClassName="infoText" alertText={<>A full year range must be selected for yearly searches.</>} />;

  date_diff_indays(date1, date2) {
    console.log('test')
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);
    console.log(Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24)))
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
}

  render() {
    return (
      <>
        <Row>
          <Col sm={8}>
            <Locations />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Times />
          </Col>
          <Col sm={4}>
            <OtherParams />
          </Col>
          <Col sm={4}>
            <Outputs />
          </Col>
        </Row>

        {this.props.hideValidForm === false && <Popup divClassName="errorDialog" textClassName="errorText" alertText={this.props.errorMessage} />}
        <div className="test">
          <SearchButton />
          <SaveSearch />
          <EmailFormPopup />

        </div>
        {/* { this.props.announce502Error != null && (
         <Popup divClassName="errorDialog" textClassName="errorText" alertText={`Internal Server error 502 - Retry Count:${this.props.announce502Error.retries}`} />
        )} */}
        {this.props.errors.length === 1 && (
          <ErrorBoundary>
            <Popup divClassName="errorDialog" textClassName="errorText" alertText={this.props.errors[0]} />
          </ErrorBoundary>

        )}
        {this.props.errors.length > 1 && (

          <div>
            <Accordion>
              <div className="alertDialog errorDialog">
                <Accordion.Toggle data-testid='accordian' onClick={() => this.toggleChevron()} className="searchErrors" style={{ paddingLeft: '0px' }} eventKey="0">
                  <span className="infoText"><Error24 primaryColor="#e61e36" style={{
                    height: '24px',
                    width: '24px', marginRight: '10px'
                  }} /> There was an error. Please click here for more details.</span> <div className="arrow"> {this.state.isOpen ? <ChevronUp16 primaryColor="#1E1E1E" secondaryColor="#707070" /> : <ChevronDown16 primaryColor="#1E1E1E" secondaryColor="#707070" />}</div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <ul className="searchErrorText">
                    <ErrorBoundary>
                    {Object.keys(this.props.errors).map(error => (
                      <li key={error + " error"}>{this.props.errors[error]}</li>
                    ))}
                    </ErrorBoundary>
                  </ul>
                </Accordion.Collapse>
              </div>
            </Accordion>
          </div>

        )}
        {this.state.searchChanged && (
          <>{this.sameSearchPopup()}</>
        )
        }
        {this.props.calculate === 'daily' && (
          <>{this.dailyPopup()}</>
        )
        }
        {this.props.calculate === 'yearly' && (
          <>{this.yearlyPopup()}</>
        )
        }
        {this.props.showSuccess === true && (
          <>
            <Popup divClassName="successDialog" textClassName="successText" alertText={this.props.successMsg} />
          </>
        )}
      </>
    );
  }
}

const SearchFormExport = connect(mapStateToProps, mapDispatchToProps)(SearchForm);
export default SearchFormExport;
