/*

*/
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tab, TabList, TabPanel } from "@ausbom/tabs";

import "react-table/react-table.css";

const mapStateToProps = state => {
  return {
    OUTPUT_TITLE_MAP: state.OUTPUT_TITLE_MAP,
    results: state.results,
    calculate: state.lastSearch.calculate,
    startMonth: state.lastSearch.startMonth,
    startYear: state.lastSearch.startYear,
    endMonth: state.lastSearch.endMonth,
    endYear: state.lastSearch.endYear,
    locations: state.lastSearch.locations,
    eCondition: state.lastSearch.eCondition,
    outputs: state.lastSearch.outputs
  };
};

class ResultTabs extends React.Component {
  DEFAULT_TAB = "tabularOption";
  NO_ACTIVE_TABS_MESSAGE = (
    <>
      Please select an option from the <strong>Output Options</strong> above to view data from a search
    </>
  );

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeResultTab: this.DEFAULT_TAB,
      selectTabMessage: "",
      outputs: {}
    };

    ResultTabs.propTypes = {
      results: PropTypes.object,
      calculate: PropTypes.string,
      startMonth: PropTypes.number,
      startYear: PropTypes.number,
      endMonth: PropTypes.number,
      endYear: PropTypes.number,
      locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      eCondition: PropTypes.string,
      outputs: PropTypes.object
    };
  }

  /**
  * @desc On load run updateTabs function.
  */
  componentDidMount() {
    this.updateTabs();
  }

  /**
  * @desc On update of component, run updateTabs function.
  */
  async componentDidUpdate() {
    this.updateTabs();
  }

  /**
   * @desc handle the tabs to be shown for outputs selected. 
   * Open the first tab if the tab that should be open is unchecked
   */
  updateTabs = () => {
    const activeResultTab = this.state.activeResultTab ? this.state.activeResultTab : this.DEFAULT_TAB
    const setActiveTab = this.getFirstActiveTab();
    if (setActiveTab) {
      if (this.props.outputs[activeResultTab].checked === false)
        this.setState({ activeResultTab: setActiveTab })
      else if (this.state.selectTabMessage)
        this.setState({ selectTabMessage: "" })
    }
    else if (!setActiveTab && !this.state.selectTabMessage)
      this.setState({ selectTabMessage: this.NO_ACTIVE_TABS_MESSAGE })
  };

  /**
   * @desc For handling the change of tabs in the results.
   * @param {*} keys the identifier for the tabs.
   */
  setKey = keys => this.setState({ activeResultTab: keys });

  /**
   * @desc Get the first tab to be shown as its not always Tabular.
   */
  getFirstActiveTab = () => {
    const outputs = Object.keys(this.props.outputs);
    for (let i = 0; i < outputs.length; i++) if (this.props.outputs[outputs[i]].checked) return outputs[i];
    return null;
  };

  /**
   * @desc Render what tabs are to be shown based on what outputs were selected.
   */
  renderMappedOutputs = () => Object.keys(this.props.outputs).map(output => this.props.outputs[output].checked === true && <Tab key={this.props.outputs[output].title} className="tabTest" label={this.props.outputs[output].title} active={this.state.activeResultTab === output} tabId="today-2" onClick={() => this.setKey(output)} />);

  render() {
    return (
      <div>
        <TabList>{this.renderMappedOutputs()}</TabList>

        <TabPanel active={this.state.activeResultTab === "tabularOption"} tabId="today-2">
          {this.props.outputs.tabularOption.component}
        </TabPanel>
        <TabPanel active={this.state.activeResultTab === "barChartOption"} tabId="7-day-2">
          {this.props.outputs.barChartOption.component}
        </TabPanel>
        <TabPanel active={this.state.activeResultTab === "stackedBarChartOption"} tabId="7-day-2">
          {this.props.outputs.stackedBarChartOption.component}
        </TabPanel>
        <TabPanel active={this.state.activeResultTab === "scatterPlotOption"} tabId="7-day-2">
          {this.props.outputs.scatterPlotOption.component}
        </TabPanel>
        <TabPanel active={this.state.activeResultTab === "exportJsonOption"} tabId="7-day-2">
          {this.props.outputs.exportJsonOption.component}
        </TabPanel>

        {this.state.selectTabMessage && (
          <div className="placeholder">
            <h5>{this.state.selectTabMessage}</h5>
          </div>
        )}
      </div>
    );
  }
}

const ResultTabsExport = connect(mapStateToProps)(ResultTabs);

export default ResultTabsExport;
