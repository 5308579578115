
const dev = {
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://gxe2j2v0l7.execute-api.ap-southeast-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_pAySz1eka",
    APP_CLIENT_ID: "6a20mla91rrs3rpkk20t2lsiv4"
  }
};

const uat = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://fbrwyomre1.execute-api.ap-southeast-2.amazonaws.com/uat"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_yX84sC8o2",
    APP_CLIENT_ID: "125u6nph550si4v6df8lcb4tb6"
  }
};

const prod = {
  s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://vijkmc6cfj.execute-api.ap-southeast-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_bR94CUu6U",
    APP_CLIENT_ID: "2h1qs7s0v0j6ev4befsdttiguh"
  }
};

let config;
if (process.env.REACT_APP_STAGE.trim().toLowerCase() === 'dev') {
  config = dev
} else if (process.env.REACT_APP_STAGE.trim().toLowerCase() === 'uat') {
  config = uat
} else if (process.env.REACT_APP_STAGE.trim().toLowerCase() === 'prod') {
  config = prod
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
