import React from 'react';
import { FaSpinner } from 'react-icons/fa'



function LoadingAnimation() {
  return (
    <>
      <div className="container main-container text-center" style={{paddingBottom: 10}}>
        <div>
        <p className="h2"><FaSpinner className="spinning" />Loading search results</p>
        </div>
      </div>
      
    </>
    )

}


export default LoadingAnimation;