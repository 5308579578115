/*
This component handles the error and success popup messages throughout the application.
*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    currentSearch: state.currentSearch,
    groups: state.groups,
    allGroups: state.allGroups
  };
};

class DropDown extends React.Component {
  constructor(props, context) {
    super(props, context);

    DropDown.propTypes = {
      selectGroupId: PropTypes.string,
      selectRef: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      optionItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      defaultItem: PropTypes.bool,
      dropDownType: PropTypes.string
    };
  }

  // The DropDown accepts class names for changing of colours for the box and the text.
  // Text for the message is also accepted.
  render = () => (
    <div className="col">
      <select ref={this.props.selectRef} data-testid={this.props.selectGroupId} id={this.props.selectGroupId} value={this.props.currentSearch[this.props.selectGroupId]} className="formDropDown dropDownWidthWide" onChange={this.props.handleDropSelect}>
        {this.props.defaultItem === true && <option value="" defaultValue></option>}
        {this.props.dropDownType === "locationGroups" && (
          <>
            {Object.keys(this.props.optionItems).map((dg, i) => (
              <option data-testid={i} key={i} value={this.props.optionItems[dg]}>
                {dg}
              </option>
            ))}
          </>
        )}
        {this.props.dropDownType === "userGroups" && (
          <>
            {/* <optgroup label="Your saved groups"> */}
            {Object.keys(this.props.optionItems).map(dg => (
              <option key={this.props.optionItems[dg]} data-id={this.props.optionItems[dg]} value={this.props.optionItems[dg]} >
                {this.props.optionItems[dg]}
              </option>
            ))}
          </>
        )}
        {this.props.dropDownType === "customGroups" && (
          <>
            {/* <optgroup label="Your saved groups"> */}
            {Object.keys(this.props.groups).map(dg => (
              <option key={this.props.groups[dg].id} data-id={this.props.groups[dg].id} value={this.props.groups[dg].locations} data-testid={this.props.groups[dg].id}>
                {this.props.groups[dg].title}
              </option>
            ))}
            {/* </optgroup> */}
            {/* <optgroup label="Other users saved groups">
              {Object.keys(this.props.allGroups).map(dg => (
                <option key={this.props.allGroups[dg].id} data-id={this.props.allGroups[dg].id} value={this.props.allGroups[dg].locations} data-testid={this.props.allGroups[dg].id}>
                  {this.props.allGroups[dg].title}
                </option>
              ))}
            </optgroup> */}
          </>
        )}
        {this.props.dropDownType === "otherParams" && (
          <>
            {Object.keys(this.props.optionItems).map((dg, i) => (
              <option disabled={this.props.optionItems[dg].disabled} key={i} value={this.props.optionItems[dg].value}>
                {this.props.optionItems[dg].display}
              </option>
            ))}
          </>
        )}
      </select>
    </div>
  );
}

const DropDownExport = connect(mapStateToProps)(DropDown);
export default DropDownExport;
