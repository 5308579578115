/*
*/
import React from 'react'
import { connect } from "react-redux";
import { addLocation, addLocationsValid, addGroups, addAllGroups, addGroupId, addErrors } from "../../../../../../actions/index";
import { Auth } from "aws-amplify";
import PropTypes from 'prop-types';
import DropDown from '../../../../../components/DropDown/DropDown'
import validateInput from '../../../../../validate-text/validateText'
import SaveGroup from './save-group'
import { API } from "aws-amplify";
import { createBrowserHistory } from "history";

// Handle props used for this component.
function mapDispatchToProps(dispatch) {
    return {
        addLocation: locations => dispatch(addLocation(locations)),
        addLocationsValid: locationsValid => dispatch(addLocationsValid(locationsValid)),
        addGroups: groups => dispatch(addGroups(groups)),
        addAllGroups: allGroups => dispatch(addAllGroups(allGroups)),
        addGroupId: groupId => dispatch(addGroupId(groupId)),
        addErrors: errors => dispatch(addErrors(errors))
    };
}

const mapStateToProps = state => {
    return {
        locations: state.currentSearch.locations,
        locationsValid: state.locationsValid,
        lastSearch: state.lastSearch,
        currentSearch: state.currentSearch,
        groups: state.groups,
        allGroups: state.allGroups,
        groupId: state.groupId,
        errors: state.errors
    };
};

class Locations extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            locationInputValue: '',
            defaultGroups: {
                "Major Airports": ["YSSY", "YMML", "YBBN", "YPPH", "YPAD", "YBCG", "YBCS", "YSCB", "YPDN", "YMHB"],
                "Defence Airports": ["YAMB", "YMES", "YBOK", "YPEA", "YPTN", "YBTL", "YWLM"],
                "NSW Airports": ["YARM", "YBNA", "YSBK", "YCFS", "YCBB", "YGLI", "YGFN", "YLIS", "YMND", "YMOR", "YMDG", "YNBR", "YPMQ", "YSRI", "YSCO", "YSTW", "YTRE", "YWLM", "YBTH", "YSCN", "YSCB", "YCOM", "YGLB", "YSWG", "YMER", "YMRY", "YSNW", "YORG", "YPKS", "YYNG", "YMAY", "YBKE", "YBHI", "YCBA", "YSDU", "YGTH", "YIVO", "YNAR", "YWLG"],
                "VIC Airports": ["YBNS", "YBLT", "YBDG", "YMES", "YMEN", "YHML", "YHSM", "YLTV", "YMNG", "YMIA", "YMMB", "YHOT", "YPOD", "YSHT", "YSWH", "YWGT", "YWBL"],
                "QLD Airports": ["YAMB", "YBAF", "YBUD", "YGLA", "YBCG", "YHBA", "YKRY", "YMYB", "YBOK", "YBRK", "YBSU", "YTNG", "YTWB", "YBWW", "YLLE", "YBDV", "YBCV", "YLRE", "YROM", "YSGE", "YTGM", "YWDH", "YCCY", "YHUG", "YJLC", "YBMA", "YRMD", "YTMO", "YTEE", "YWTN", "YCMT", "YEML", "YBHM", "YBMK", "YMRB", "YBPN", "YBTL", "YWIS", "YBKT", "YBCS", "YCOE", "YCKN", "YGTN", "YHID", "YIFL", "YKOW", "YLHR", "YMBA", "YMTI", "YNTN", "YBSG", "YBWP"],
                "SA Airports": ["YPED", "YKSC", "YMTG", "YMBD", "YPPF", "YPAG", "YPLC", "YREN", "YWHA", "YLEC", "YOLD", "YPWR", "YCBP", "YOOM", "YCDU", "YERN"],
                "WA Airports": ["YCUN", "YGEL", "YPJT", "YMEK", "YMOG", "YPEA", "YRTI", "YPKG", "YLST", "YLEO", "YSCR", "YFRT", "YABA", "YBLN", "YESP", "YTRA", "YWBR", "YCAR", "YSHK", "YWLU", "YGLS", "YARG", "YBRY", "YBGD", "YCHK", "YCWA", "YFDF", "YNWN", "YPBO", "YTEF", "YBWX", "YPKA", "YPKU", "YPLM", "YOLW", "YPPD", "YSOL", "YBRM", "YCIN", "YDBY", "YFTZ", "YHLC", "YLBD", "YTST"],
                "TAS Airports": ["YDPO", "YFLI", "YKII", "YMLT", "YSTH", "YSRN", "YWYY"],
                "NT Airports": ["YBTI", "YELD", "YPGV", "YGTE", "YJAB", "YMGD", "YMHU", "YNGU", "YPKT", "YPTN", "YHOO", "YTNK", "YTGT", "YBAS", "YAYE", "YYND", "YTTI"],
                "External Airports": ["YLHI", "YSNF", "YPXM", "YPCC"],
                "Adelaide": ['YPAD'],
                "Brisbane": ['YBBN'],
                "Cairns": ['YBCS'],
                "Darwin": ['YPDN'],
                "Hobart": ['YMHB'],
                "Melbourne (Tullamarine)": ['YMML'],
                "Perth": ['YPPH'],
                "Sydney": ['YSSY']
            }
        };

        Location.propTypes = {
            groupId: PropTypes.string,
            groups: PropTypes.array,
            locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
            locationsValid: PropTypes.bool,
            errors: PropTypes.array,
            lastSearch: PropTypes.lastSearch,
            addLocationsValid: PropTypes.func,
            addLocation: PropTypes.func,
            addGroups: PropTypes.func,
            addGroupId: PropTypes.func,
            addErrors: PropTypes.func
        }
        this.groupNameInput = React.createRef();
        this.defaultGroupInput = React.createRef();
        this.customGroupInput = React.createRef();
        this.reLoad = this.reLoad.bind(this)
    }

    /**
     * @desc On page load, set the current location input value as the locations in props, seperating the array with ,.
     * @try Get the session info from AWS Auth and if successful, get the jwtToken for the API call.
     * @catch If error, user is not authed, send user back to login page.
     * @try do API call and assign returned groups and shared groups to the relevent props. 
     * @error log error. (add alert?)
     */
    async componentDidMount() {
        this.setState({ locationInputValue: this.props.locations.join(',') })
        let jwtToken
        try {
            const sessionInfo = await Auth.currentSession();
            jwtToken = sessionInfo.getIdToken().getJwtToken()
        } catch (error) {
            const customHistory = createBrowserHistory();
            customHistory.replace('/#/login')
            customHistory.go()
        }
        const header = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        }
        await API.get("groups", `/groups`, header).then(response => {
            this.props.addGroups({ groups: response.user })
            this.props.addAllGroups({ allGroups: response.group })
        }).catch(error => {
            console.log(error)
        })
    }

    /**
     * @desc If there is a change in the location prop, set the location input as the new prop value.
     * @param {*} prevProps holds the previous state of props before change.
     */
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.locations) !== JSON.stringify(this.props.locations)) {
            this.setState({ locationInputValue: this.props.locations.join(',') })

        }
    }

    /**
     * @desc Handles verifying the location ids typed into the text box.
     * Validates the input through the validateInput function.
     * @param {event} event used to get the value of the text input box.
     * @result Display error if validateInput returns an error, otherwise add the locations to the locations prop.
     */
    handleLocationChange = (event) => {
        const value = event.target.value
        this.setState({ locationInputValue: value })
        let validInput = validateInput.validateInput(value)

        this.props.addLocationsValid({ locationsValid: validInput.valid });
        this.props.addErrors({ errors: !validInput.hideAlert ? [validInput.alertMessage] : [] })

        if (validInput.valid) {
            this.props.addLocation({ locations: validInput.body });
        }
    };

    /**
     * @desc When the user changes the location dropdowns, it updates the relevant props.
     * @param {event} event used to determine what dropdown was changed and get the value of the selection.
     * @result populate the input field with the selected dropdown locations and changes the not selected
     *         dropdown to an empty selection.
     */
    handleDropdownSelect = (event) => {
        var optionElement = event.target.childNodes[1]

        var option = optionElement.getAttribute('data-id');
        const { id, value } = event.target

        this.props.addLocation({ locations: value.split(',') });
        this.props.addGroupId({ groupId: option });
        if (this.customGroupInput.current.value === "" && this.defaultGroupInput.current.value === "") {
            this.props.addLocationsValid({ locationsValid: false })
        } else {
            this.props.addLocationsValid({ locationsValid: true })
        }
        if (id === "defaultGroups") {
            this.customGroupInput.current.value = ""
        }
        else if (id === "customGroups")
            this.defaultGroupInput.current.value = ""
    }

    /**
     * @desc on save group, call the componentDidMount function to get the new saved group in the dropdown.
     */
    reLoad() {
        this.componentDidMount();
    }

    render() {
        return (
            <div className="searchBoxes search-components">
                <div>
                    <h4 className='titleText'>Locations</h4>

                    <>
                        <div className="row">
                            <div className="col">
                                <label className="dropDownLabel">Select a group</label>
                            </div>
                            <div className="col">
                                <label className="dropDownLabel">Select a custom group</label>
                            </div>
                        </div>
                        <div className="row">
                            <DropDown
                                defaultItem={true}
                                dropDownType='locationGroups'
                                selectGroupId='defaultGroups'
                                selectRef={this.defaultGroupInput}
                                optionItems={this.state.defaultGroups}
                                handleDropSelect={this.handleDropdownSelect}
                            />
                            <DropDown
                                defaultItem={true}
                                dropDownType='customGroups'
                                selectGroupId='customGroups'
                                selectRef={this.customGroupInput}
                                optionItems={this.props.groups}
                                handleDropSelect={this.handleDropdownSelect}
                            />

                        </div>
                        <input
                            data-testid='locations-input'
                            id="location-text"
                            name="locations"
                            className={(!this.props.locationsValid && Object.keys(this.props.lastSearch).length !== 0) || (!this.props.locationsValid && this.state.locationInputValue.length !== 0) ? 'formInputError inputWidthWide' : 'formInput inputWidthWide'}
                            type="text"
                            placeholder="Enter locations"
                            autoComplete="off"
                            onChange={this.handleLocationChange}
                            value={this.state.locationInputValue}
                        />
                        <div >
                            <SaveGroup onChange={this.reLoad} />
                        </div>
                    </>
                </div>
            </div>
        )
    }
}
const LocationsExport = connect(mapStateToProps, mapDispatchToProps)(Locations);

export default LocationsExport
