/*
This component handles the error and success popup messages throughout the application.
*/
import React from "react";
import PropTypes from 'prop-types';
import { FaTimes } from "react-icons/fa";
import { addShowSuccess } from "../../../actions/index";
import { connect } from 'react-redux'
import Information24 from '@ausbom/icon/lib/icons/system/Information24'
import Error24 from '@ausbom/icon/lib/icons/system/Error24'
import Success24 from '@ausbom/icon/lib/icons/system/Success24'

function mapDispatchToProps(dispatch) {
    return {
        addShowSuccess: showSuccess => dispatch(addShowSuccess(showSuccess))
    };
}

const mapStateToProps = state => {
    return {
        showSuccess: state.showSuccess
    };
};

class Alert extends React.Component {
    constructor(props, context) {
        super(props, context);
        Alert.propTypes = {
            divClassName: PropTypes.string,
            textClassName: PropTypes.string,
            alertText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            addShowSuccess: PropTypes.func
        }
        this.closeAlert = this.closeAlert.bind(this)

    }

    closeAlert() {
        this.props.addShowSuccess({ showSuccess: false })
    }

    // The popup accepts class names for changing of colours for the box and the text.
    // Text for the message is also accepted.
    render() {
        return (
            <>
                {this.props.divClassName === 'successDialog' &&
                    <div className='alertDialog successDialog'>
                        <span className='alertText'><Success24 primaryColor="#00a087" style={{height: '24px',
    width: '24px', marginRight: '10px'}}/>{this.props.alertText} <FaTimes data-testid='closeButton' onClick={this.closeAlert} className='successClose' size="1.5em" /></span>
                        
                    </div>
                }
                {this.props.divClassName === 'errorDialog' &&
                    <div className='alertDialog errorDialog'>
                        <span className='alertText'><Error24 primaryColor="#e61e36" style={{height: '24px',
    width: '24px', marginRight: '10px'}}/>{this.props.alertText}</span>
                    </div>
                }
                {this.props.divClassName === 'infoDiv' &&
                    <div className='alertDialog infoDialog'>
                        <span className='alertText'><Information24 primaryColor="#2461e5" style={{height: '24px',
    width: '24px', marginRight: '10px'}}/>
 {this.props.alertText}</span>
                    </div>
                }
            </>
        )
    }
}
const Popup = connect(mapStateToProps, mapDispatchToProps)(Alert)

export default Popup