/*
This component contains the code for the header aspect of the site.
- Navbar
- Authentication 
*/

import React from "react";
import Navbar from 'react-bootstrap/Navbar'
import { withRouter, Link } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Routes from "../../Routes";
import Logo from "../../images/bom-logo.svg";
import PropTypes from 'prop-types';
import { MdMenu } from "react-icons/md";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      username: null
    };
    Header.propTypes = {
      history: PropTypes.object,
    }

    this.handleLogout = this.handleLogout.bind(this)
  }

  /**
  * @desc Checks on load if the user has authenticated in the current session or not.
  * @try Checks user current session and if authed, calles the userHasAuthenticated function.
  * @catch If not authed then the user is pushed back to the login page.
  * @result The state of isAuthenticating is set to false.
  */
  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
      const user = await Auth.currentAuthenticatedUser();
      if(user!=null){
        this.setState({ username: user.username });
      }
    }
    catch (e) {
      if (e !== 'No current user') {
        this.props.history.push("/login");
      }
    }
    this.setState({ isAuthenticating: false });
  }

  /**
  * @desc Sets the user is authenticated to the state.
  * @param {boolean} authenticated the value to set isAuthenticated.
  * @result The state of isAuthenticated is set to the @param authenticated.
  */
  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }


  /**
  * @desc Function to handle the logout button in the header.
  * Function will run the AWS signOut function and call userHasAuthenticated with the param false.
  * @result The user will be pushed to the login page.
  */
  async handleLogout() {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };
    return (
      !this.state.isAuthenticating &&
      <>
        <Navbar className="navbar mr-auto" style={{ backgroundColor: "#094ad0", color: "white" }}>
          <div className="container">
            <Navbar.Brand href="http://www.bom.gov.au/">
              <img
                alt="bom logo"
                src={Logo}
                width="192"
                className="header-logo"
              />
            </Navbar.Brand>
            <Nav className="mx-auto">
              <Nav.Link as={Link} to="/">
                <h2 className="nav-heading">Aviation Verification System</h2>
                <h4 className="small-heading">Aviation Verification System</h4>

              </Nav.Link>
            </Nav>
            {
              this.state.isAuthenticated ?
                <NavDropdown title={<MdMenu size="1.8em" />} id="nav-dropdown" className="ml-auto">
                  <NavDropdown.Item as={Link} to="/user-profile">User Profile</NavDropdown.Item>
                  <NavDropdown.Item href="https://bom365.sharepoint.com/:b:/s/IDP/EcygHHFo_L1NrdcIv6OClBoBQjf5KghXQHQTuFNjEyfPKQ?e=iURE2O" target="_blank" rel="noopener noreferrer">User Guide</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item data-testid='logout' onClick={this.handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
                :
                <div className="ml-auto"></div>
            }
            <div>{this.state.username}</div>
            <sub style={{ paddingLeft:"15px",textAlign: "centre"}}>v1.1.6</sub>
          </div>
        </Navbar>
        <Routes childProps={childProps} />
      </>
    )
  }
}

export default withRouter(Header);