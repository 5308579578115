import React from 'react'
import { Bubble as Scatter } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import saveAs from 'file-saver'
import Download24 from '@ausbom/icon/lib/icons/system/Download24'
import Button from '@ausbom/button'

function mapStateToProps(state) {
    return {
        results: state.results,
        startMonth: state.lastSearch.startMonth,
        endMonth: state.lastSearch.endMonth,
        startYear: state.lastSearch.startYear,
        endYear: state.lastSearch.endYear,
        locations: state.lastSearch.locations,
        eCondition: state.lastSearch.eCondition,
        calculate: state.lastSearch.calculate,
        filename: `${state.lastSearch.calcum7ulate}_${state.lastSearch.startYear}-${state.lastSearch.startMonth}_${state.lastSearch.endYear}-${state.lastSearch.endMonth}_ScatterPlot.png`

    }
}

function ScatterPlot(props) {

    const scatter = 'bubble'

    ScatterPlot.propTypes = {
        results: PropTypes.object,
        startMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        startYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        endMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        endYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        eCondition: PropTypes.string,
        calculate: PropTypes.string,
        filename: PropTypes.string
    }

    var dataArray = []
    // Create the data sets to be plotted on the graph.
    // If data is not equal to null, change the decimals to 2 places, otherwise add null to the dataArray.
    for (var key in props.results) {
        if (props.results[key]['far'] !== null && props.results[key]['pod'] !== null) {
            dataArray.push({ x: props.results[key]['far'].toFixed(2), y: props.results[key]['pod'].toFixed(2), r: props.results[key]['hours_observed_below_minima'] })
        } else {
            dataArray.push({ x: props.results[key]['far'], y: props.results[key]['pod'], r: props.results[key]['hours_observed_below_minima'] })
        }
    }
    const environmentalCondition = props.eCondition === "weather" ?  "thunderstorms" : props.eCondition;
    const chartOptions = {
        maintainAspectRatio: true,
        title: {
            display: true,
            text: `Data for ${props.startMonth}/${props.startYear} - ${props.endMonth}/${props.endYear} for locations ${props.locations} for ${environmentalCondition} environmental condition`
        },
        scales: {
            xAxes: [{
                display: true,
                ticks: {
                    max: 1,
                    min: 0
                },
                labels: {
                    show: true
                },
                scaleLabel: {
                    display: true,
                    labelString: 'False Alarm Rate'
                }
            }],
            yAxes: [{
                display: true,
                ticks: {
                    max: 1,
                    min: 0
                },
                labels: {
                    show: true
                },
                scaleLabel: {
                    display: true,
                    labelString: 'PoD'
                }
            }]
        },
        // for some weird reason scatter tooltips require callbacks to generate the correct labels
        tooltips: {
            callbacks: {
                title: function (tooltipItem, data) {
                    var monthLabel = data.labels[tooltipItem[0].index] || ''
                    return monthLabel
                },
                label: function (tooltipItem, data) {
                    var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    return `(FaR: ${value.x}, PoD: ${value.y}) HOBM: ${value.r}`
                }
            }
        }
    }

    const chartData = {
        labels: Object.keys(props.results),
        datasets: [
            {
                type: scatter,
                label: 'Months',
                backgroundColor: 'rgb(36,97,229, 0.8)',
                showLine: false,
                data: dataArray
            },
            // {
            //     type: 'line',
            //     label: 'Nil Skill',
            //     data: [{ x: 0, y: 0 }, { x: 1, y: 1 }],
            //     backgroundColor: 'rgba(0,0,0,0)',
            //     borderColor: '#E66500'
            // }
        ]
    }

    /**
   * @desc Take a snapshot of the canvas that the char is displayed on 
   * and make a file of it for the user to download.
   */
    function downloadPng() {
        var scatterPlotCanvas = document.querySelector("#scatterChart")
        scatterPlotCanvas.setAttribute("id", "scatterChart")
        var ctx = scatterPlotCanvas.getContext("2d");

        ctx.globalCompositeOperation = 'destination-over'
        ctx.fillStyle = "#f9f9fa";
        ctx.fillRect(0, 0, scatterPlotCanvas.width, scatterPlotCanvas.height);

        if (window.navigator.msSaveOrOpenBlob) {
            var blob = scatterPlotCanvas.msToBlob()
            window.navigator.msSaveBlob(blob, props.filename)
        } else {
            scatterPlotCanvas.toBlob(function (blob) {
                saveAs(blob, props.filename)
            })
        }
        ctx.globalCompositeOperation = 'source-over'
    }

    return (
        <>
            <Button data-testid='downloadChart' id="downloadButton" style={{ float: "right" }} onClick={downloadPng} small variant="secondary">
                Download .png <Download24 primaryColor="#2461E5" secondaryColor="#2461E5" />
            </Button>

            <Scatter
                id="scatterChart"
                data={chartData}
                options={chartOptions}
            />
        </>
    )
}

const ScatterPlotComponent = connect(mapStateToProps)(ScatterPlot)
export default ScatterPlotComponent


