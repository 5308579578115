import React from "react";
import ReactTable from "react-table";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { addTimePeriod, addLocation, searchData, allSearchData, addComparison, addECondition, addHourRange, addCalculate, addToggle, addLocationsValid, addOutput, addEditSearch, addEditPopup, addErrors } from "../../../../../actions/index";
import PropTypes from "prop-types";
import { FaTrashAlt } from "react-icons/fa";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { Tab as NotTab, TabList, TabPanel } from "@ausbom/tabs";
import Button from "@ausbom/button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Times from "../search-form/time-period-container/time-component";
import OtherParams from "../search-form/other-params-container/other-params-component";
import Locations from "../search-form/locations-container/locations-component";
import Outputs from "../search-form/output-options-container/output-component";
import Popup from "../../../../components/Popup/Popup"
import { createBrowserHistory } from "history";
import ErrorBoundary from "../../../../components/ErrorBoundry";

// Handle props used for this component.
function mapDispatchToProps(dispatch) {
  return {
    addTimePeriod: timePeriod => dispatch(addTimePeriod(timePeriod)),
    addErrors: errors => dispatch(addErrors(errors)),
    addLocation: locations => dispatch(addLocation(locations)),
    searchData: searches => dispatch(searchData(searches)),
    allSearchData: allSearches => dispatch(allSearchData(allSearches)),
    addComparison: comparison => dispatch(addComparison(comparison)),
    addECondition: eCondition => dispatch(addECondition(eCondition)),
    addHourRange: hourRange => dispatch(addHourRange(hourRange)),
    addCalculate: calculate => dispatch(addCalculate(calculate)),
    addToggle: toggle => dispatch(addToggle(toggle)),
    addLocationsValid: locationsValid => dispatch(addLocationsValid(locationsValid)),
    addOutput: output => dispatch(addOutput(output)),
    addEditSearch: editSearch => dispatch(addEditSearch(editSearch)),
    addEditPopup: editPopup => dispatch(addEditPopup(editPopup))
  };
}

const mapStateToProps = state => {
  return {
    currentSearch: state.currentSearch,
    searches: state.searches,
    locationsValid: state.locationsValid,
    toggle: state.toggle,
    outputs: state.currentSearch.outputs,
    allSearches: state.allSearches,
    locations: state.currentSearch.locations,
    comparison: state.currentSearch.comparison,
    timePeriod: state.currentSearch.timePeriod,
    eCondition: state.currentSearch.eCondition,
    hourRange: state.currentSearch.hourRange,
    startMonth: state.currentSearch.startMonth,
    endMonth: state.currentSearch.endMonth,
    startYear: state.currentSearch.startYear,
    endYear: state.currentSearch.endYear,
    periodOption: state.currentSearch.periodOption,
    startDay: state.currentSearch.startDay,
    endDay: state.currentSearch.endDay,
    calculate: state.currentSearch.calculate,
    editPopup: state.editPopup,
    errors: state.errors,
    editSearch: state.editSearch
  };
};

class SavedSearches extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      selectedRow: "",
      isLoading: false,
      hideSuccess: true,
      tab: "currentSearch",
      selectedTab: "today",
      showEdit: false,
      invalidOutputs: false
    };

    SavedSearches.propTypes = {
      searches: PropTypes.array,
      allSearches: PropTypes.array,
      toggle: PropTypes.bool,
      locationsValid: PropTypes.bool,
      errors: PropTypes.array,
      outputs: PropTypes.object,
      addLocation: PropTypes.func,
      addTimePeriod: PropTypes.func,
      addComparison: PropTypes.func,
      addECondition: PropTypes.func,
      addHourRange: PropTypes.func,
      addCalculate: PropTypes.func,
      addToggle: PropTypes.func,
      addErrors: PropTypes.func,
      addLocationsValid: PropTypes.func,
      addOutput: PropTypes.func,
      searchData: PropTypes.func,
      setKey: PropTypes.func,
      addEditPopup: PropTypes.func,
      editPopup: PropTypes.bool,
      editSearch: PropTypes.object,
      addEditSearch: PropTypes.func,
      locations: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      comparison: PropTypes.string,
      timePeriod: PropTypes.string,
      eCondition: PropTypes.string,
      hourRange: PropTypes.string,
      startMonth: PropTypes.number,
      endMonth: PropTypes.number,
      startYear: PropTypes.number,
      endYear: PropTypes.number,
      periodOption: PropTypes.string,
      startDay: PropTypes.number,
      endDay: PropTypes.number,
      calculate: PropTypes.string,
      allSearchData: PropTypes.func
    };

    this.render = this.render.bind(this);
    this.fillForm = this.fillForm.bind(this);
    this.deleteSearch = this.deleteSearch.bind(this);
    this.deleteSearchPopup = this.deleteSearchPopup.bind(this);
    this.editSearchPopup = this.editSearchPopup.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  /**
  * @desc Check if user is authed, if not send to login page.
  * If authed then retrieve the users saved searches and user groups saved searches.
  */
  async componentDidMount() {
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (error) {
      const customHistory = createBrowserHistory();
      customHistory.replace('/#/login')
      customHistory.go()
    }
    const header = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };
    try {
      let data = await API.get("groups", "/searches", header);
      this.props.searchData({ searches: data.user });
      this.props.allSearchData({ allSearches: data.group });
    } catch (error) {
      this.handlePopupAlerts("hideValidForm", false, "errorMessage", "There was an error loading your saved searches. Please try again or contact our support.");
    }
  }

  /**
   * @desc Check if an output has been selected to determine if edit search is valid.
   * @param {*} prevProps holds the previous state of props before change.
   */
  async componentDidUpdate(prevProps) {
    if (this.props.outputs !== prevProps.outputs) {
      for (const key in this.props.outputs) {
        if (this.props.outputs[key].checked === true) {
          await this.setState({ invalidOutputs: false })
          break;
        } else {
          await this.setState({ invalidOutputs: true })
        }
      }
    }
  }

  /**
   * @desc If fill form is selected, update the form to reflect the selected row and update the props.
   * @param {*} option if the button was clicked under searches or allSearches.
   * @param {*} row What row number was the fill form button clicked on.
   */
  async fillForm(option, row) {
    const data = this.props[option][row._index];
    this.props.addCalculate({ calculate: data.calculate });
    this.props.addLocation({ locations: data.locations });
    this.props.addTimePeriod({ timePeriod: data.periodType });
    this.props.addComparison({ comparison: data.comparison });
    this.props.addECondition({ eCondition: data.e_conditions });
    this.props.addHourRange({ hourRange: data.hourRange });
    this.props.addToggle({ toggle: !this.props.toggle });
    this.props.addLocationsValid({ locationsValid: true });

    const savedOutputs = data.outputs ? data.outputs : [];
    const currentOutputs = Object.keys(this.props.outputs);
    for (let i = 0; i < currentOutputs.length; i++) this.props.addOutput({ key: currentOutputs[i], value: savedOutputs.indexOf(currentOutputs[i]) !== -1 });
  }

  /**
   * @desc display the delete search popup modal.
   * @param {*} row use to determine what row the button was clicked on.
   */
  async deleteSearchPopup(row) {
    await this.setState({ selectedRow: row._index });
    this.handleModel("show", true);
  }

  /**
   * @desc Open the edit search popup and fill the edit search form with the selections from the row selected.
   * @param {*} option If it was selected from search or allSearches.
   * @param {*} row What row was the button clicked on.
   */
  async editSearchPopup(option, row) {
    await this.setState({ selectedRow: row._index });
    let group = this.props.searches[this.state.selectedRow].group;
    this.props.addEditPopup({ editPopup: true });
    this.props.addEditSearch({
      editSearch: {
        locations: this.props.locations,
        comparison: this.props.comparison,
        timePeriod: this.props.timePeriod,
        eCondition: this.props.eCondition,
        hourRange: this.props.hourRange,
        startMonth: this.props.startMonth,
        endMonth: this.props.endMonth,
        startYear: this.props.startYear,
        endYear: this.props.endYear,
        periodOption: this.props.periodOption,
        startDay: this.props.startDay,
        endDay: this.props.endDay,
        calculate: this.props.calculate,
        outputs: this.props.outputs,
        group: group
      }
    });
    const data = this.props[option][row._index];
    this.props.addCalculate({ calculate: data.calculate });
    this.props.addLocation({ locations: data.locations });
    this.props.addTimePeriod({ timePeriod: data.periodType });
    this.props.addComparison({ comparison: data.comparison });
    this.props.addECondition({ eCondition: data.e_conditions });
    this.props.addHourRange({ hourRange: data.hourRange });
    this.props.addToggle({ toggle: !this.props.toggle });
    this.props.addLocationsValid({ locationsValid: true });
    this.props.addErrors({ errors: [] })
    const savedOutputs = data.outputs ? data.outputs : [];
    const currentOutputs = Object.keys(this.props.outputs);
    for (let i = 0; i < currentOutputs.length; i++) this.props.addOutput({ key: currentOutputs[i], value: savedOutputs.indexOf(currentOutputs[i]) !== -1 });
    this.handleModel("showEdit", true);
  }

  /**
   * @desc Get the jwtToken from the authed user, if not authed, send user to login page.
   * Get id to determine what row is being deleted and the user group assigned to the search.
   * Build the API call and send off delete request.
   * @todo display success and error alerts.
   */
  async deleteSearch() {
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (error) {
      const customHistory = createBrowserHistory();
      customHistory.replace('/#/login')
      customHistory.go()
    }
    let specialId = this.props.searches[this.state.selectedRow].id;
    let group = this.props.searches[this.state.selectedRow].group;
    let otherId = this.state.selectedRow;
    this.setState({ isLoading: true });
    try {
      const header = {
        headers: { Authorization: `Bearer ${jwtToken}` },
        body: {
          task: `delete`,
          id: `${specialId}`,
          locations: `${this.props.locations}`,
          group: `${group}`
        }
      };
      await API.put("groups", `/searchess`, header);
    } catch (error) {
      console.log(error);
    }
    this.handleModel("show", false);
    let searches = this.props.searches;
    delete searches[otherId];
    this.props.searchData({ searches: searches });
    this.handlePopupAlerts("hideSuccess", false, "successMessage", "Search successfully deleted!");
    this.setState({ isLoading: false });
  }

  /**
   * @desc Get the jwtToken from the authed user, if not authed, send user to login page.
   * Get saved search ID from row selected and build API request.
   * Send off API request.
   * @todo display success and errors alerts.
   */
  async updateSearch() {
    let jwtToken
    try {
      const sessionInfo = await Auth.currentSession();
      jwtToken = sessionInfo.getIdToken().getJwtToken()
    } catch (error) {
      const customHistory = createBrowserHistory();
      customHistory.replace('/#/login')
      customHistory.go()
    }
    let specialId = this.props.searches[this.state.selectedRow].id;
    var time;
    if (this.props.periodOption === "default") {
      time = this.props.timePeriod;
    } else {
      time = `${this.props.startYear}/${this.props.startMonth}/${this.props.startDay} - ${this.props.endYear}/${this.props.endMonth}/${this.props.endDay}`;
    }
    const outputsArray = Object.keys(this.props.outputs).filter(output => this.props.outputs[output].checked === true);
    const header = {
      headers: { Authorization: `Bearer ${jwtToken}` },
      body: {
        task: 'edit',
        locations: `${this.props.locations}`,
        periodType: `${time}`,
        start_day: `${this.props.startDay}`,
        start_month: `${this.props.startMonth}`,
        start_year: `${this.props.startYear}`,
        end_day: `${this.props.endDay}`,
        end_month: `${this.props.endMonth}`,
        end_year: `${this.props.endYear}`,
        comparison: `${this.props.comparison}`,
        e_conditions: `${this.props.eCondition}`,
        hourRange: `${this.props.hourRange}`,
        calculate: `${this.props.calculate}`,
        group: `${this.props.editSearch.group}`,
        id: `${specialId}`,
        outputs: `${outputsArray}`
      }
    };
    await API.put("groups", `/searchess`, header)
      .then(() => {
        this.componentDidMount();
        this.handlePopupAlerts("hideSuccess", false, "successMessage", "Search successfully saved!");
      })
      .catch(error => {
        console.log(error);
      });
    this.handleModel("showEdit", false);
  }

  /**
   * @desc Accepts model name and true or false for closing it or opening it.
   * @param {string} variable To determine what modal to show.
   * @param {boolean} bool true or false.
   */
  handleModel(variable, bool) {
    this.setState({ showTitleAlert: true });
    this.setState({ [variable]: bool });
  }

  /**
  * @desc builds the popup alert to display for success or errors.
  * @param {string} variable the state variable to assign true or false.
  * @param {boolean} bool is assigned to the variable.
  * @param {string} textVariable The type of alert to be displayed.
  * @param {string} textMessage The text to be displayed in the alert.
  */
  handlePopupAlerts(variable, bool, textVariable, textMessage) {
    this.setState({ [textVariable]: textMessage });
    this.setState({ [variable]: bool });
  }

  /**
  * @desc determines if valid input and output has been selected, if not, disable update button.
  */
  isDisabled() {
    if (this.props.locationsValid === true && this.state.invalidOutputs === false) {
      return false
    } else {
      return true
    }
  }

  fixupWeatherEcondition = (item) => {
    // add new column to display thunderstorms in the list
    item.econditions = item.e_conditions === "weather" ?  "thunderstorms" : item.e_conditions;
    return item;
  }

  render() {
    const data = this.props.searches.map(this.fixupWeatherEcondition)
    const allData = this.props.allSearches.map(this.fixupWeatherEcondition);
    const columns = [
      {
        Header: "Title",
        accessor: "title" // String-based value accessors!
      },
      {
        Header: "Locations",
        accessor: "locations",
        style: { whiteSpace: "normal" }, // allow for words wrap inside only this cell
        Cell: ({ row }) => `${row.locations.join(", ")}.`
      },
      {
        Header: "Time Period",
        accessor: "periodType",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Comparison",
        accessor: "comparison",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Enviromental Condition",
        accessor: "econditions",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Hour Range",
        accessor: "hourRange",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Calculated",
        accessor: "calculate",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Outputs",
        accessor: "outputs",
        style: { whiteSpace: "normal" }, // allow for words wrap inside only this cell
        Cell: ({ row }) => row.outputs && `${row.outputs.map(output => this.props.outputs[output].title).join(", ")}.`
      },
      {
        Header: "Group",
        accessor: 'group'
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <Button
              data-testid='fillYourSearch'
              className="tableButton"
              id={row._index}
              onClick={() => {
                this.fillForm("searches", row);
                this.props.setKey("currentSearch");
              }}
              small
            >
              Fill form
            </Button>
            <br />
            <Button
              data-testid='editSearch'
              className="tableButton"
              id={row._index}
              onClick={() => {
                this.editSearchPopup("searches", row);
              }}
              small
            >
              Edit{" "}
            </Button>
            <br />
            <Button data-testid='deleteSearch'
              className="tableButton" id={row._index} onClick={() => { this.deleteSearchPopup(row) }} small>
              <FaTrashAlt style={{ pointerEvents: "none" }} />
            </Button>
          </>
        )
      }
    ];
    const columns2 = [
      {
        Header: "Title",
        accessor: "title" // String-based value accessors!
      },
      {
        Header: "Locations",
        accessor: "locations",
        style: { whiteSpace: "normal" }, // allow for words wrap inside only this cell
        Cell: ({ row }) => `${row.locations.join(", ")}.`
      },
      {
        Header: "Time Period",
        accessor: "periodType",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Comparison",
        accessor: "comparison",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Enviromental Condition",
        accessor: "econditions",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Hour Range",
        accessor: "hourRange",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Calculated",
        accessor: "calculate",
        style: { whiteSpace: "normal" } // allow for words wrap inside only this cell
      },
      {
        Header: "Outputs",
        accessor: "outputs",
        style: { whiteSpace: "normal" }, // allow for words wrap inside only this cell
        Cell: ({ row }) => row.outputs && `${row.outputs.map(output => this.props.outputs[output].title).join(", ")}.`
      },
      {
        Header: "Group",
        accessor: 'group'
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <Button
              data-testid='fillSharedSearch'
              className="tableButton"
              id={row._index}
              onClick={() => {
                this.fillForm("allSearches", row);
                this.props.setKey("currentSearch");
              }}
              small
            >
              Fill form
            </Button>
            <br />
          </>
        )
      }
    ];

    return (
      <div>
        <TabList>
          <NotTab data-testid='youSearches' className="tabTest" label="Your searches" active={this.state.selectedTab === "today"} tabId="today-2" onClick={() => this.setState({ selectedTab: "today" })} />
          <NotTab data-testid='sharedSearches' label="Shared searches" active={this.state.selectedTab === "7day"} tabId="7-day-2" onClick={() => this.setState({ selectedTab: "7day" })} />
        </TabList>
        <TabPanel active={this.state.selectedTab === "today"} tabId="today-2">
          <ReactTable data={data} columns={columns} defaultPageSize={10} />{" "}
        </TabPanel>
        <TabPanel active={this.state.selectedTab === "7day"} tabId="7-day-2">
          <ReactTable data={allData} columns={columns2} defaultPageSize={10} />{" "}
        </TabPanel>

        <Modal data-testid='editModal' size="xl" show={this.state.showEdit} onHide={() => this.handleModel("showEdit", false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update search </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Locations />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Times />
              </Col>
              <Col sm={4}>
                <OtherParams />
              </Col>
              <Col sm={4}>
                <Outputs />
              </Col>
            </Row>
            {this.props.errors.length === 1 && (
              <ErrorBoundary>
                <Popup divClassName="errorDialog" textClassName="errorText" alertText={this.props.errors[0]} />
              </ErrorBoundary>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button data-testid='updateSearch' disabled={this.isDisabled()} onClick={this.updateSearch} small>
              Update search
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal size="sl" show={this.state.show} data-testid='deleteModal' onHide={() => this.handleModel("show", false)}>
          <Modal.Header data-testid='deleteModal2' closeButton>
            <Modal.Title>Delete search </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="locationsPara">Are you sure you want to delete this search?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button data-testid='confirmDelete' onClick={this.deleteSearch} small>
              Delete search
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const SavedSearchesExport = connect(mapStateToProps, mapDispatchToProps)(SavedSearches);

export default SavedSearchesExport;
